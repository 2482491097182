import { useIntl } from '@jetshop/intl';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems
} from '@jetshop/ui/DropdownMenu';
import React from 'react';
import { css, cx } from 'linaria';
import { ReactComponent as Caret } from '../../svg/Caret.svg';
import { ReactComponent as Check } from '../../svg/Check.svg';
import ButtonWithLoading from '../ui/Button';
import { theme } from '../Theme';
import { LargeParagraph } from '../ui/text/LargeParagraph';
import { Heading3 } from '../ui/text/Heading3';
import { styled } from 'linaria/react';

export function VariantSelector({
  product,
  variantHandler,
  showValidation,
  disableOutOfStock,
  setDisabled
}) {
  return (
    <div className={sharedStyles}>
      {product.variants.options.map((option, index) => {
        let showValidationMessage = false;
        if (
          showValidation &&
          variantHandler.getMissingOptions()?.includes(option)
        ) {
          showValidationMessage = true;
        }

        const lotsOfOptionValueChars = !!option?.values?.find(
          v => v?.length > 5
        );

        if (option?.values?.length > 15 || lotsOfOptionValueChars) {
          return (
            <DropdownVariantSelect
              key={option.name}
              option={option}
              variantHandler={variantHandler}
              showValidationMessage={showValidationMessage}
              doNotDisable={index === 0}
              setDisabled={setDisabled}
            />
          );
        }

        return (
          <ButtonSelect
            option={option}
            variantHandler={variantHandler}
            key={option.name}
            showValidationMessage={showValidationMessage}
            disableOutOfStock={disableOutOfStock}
            doNotDisable={index === 0}
            setDisabled={setDisabled}
          />
        );
      })}
    </div>
  );
}

const sharedStyles = css`
  .heading-3 {
    margin-bottom: 10px;
  }
`;

const ButtonSelectWrapper = styled('div')`
  margin: 1em 0;

  .options-row {
    display: flex;
    flex-wrap: wrap;
  }
  button {
    width: calc((100% / 5) - 10px);
    height: 45px;
    border: none;
    border-radius: 2px;
    background: ${theme.colors.lightgrey};
    margin: 0 10px 10px 0;
    padding: 0;

    .large-paragraph {
      color: ${theme.colors.black};
    }

    &.selected {
      outline: 2px solid ${theme.colors.black};
    }

    &.disabled {
      .large-paragraph {
        color: ${theme.colors.black + 66};
      }
    }
  }
`;

function ButtonSelect({
  option,
  variantHandler,
  showValidationMessage,
  disableOutOfStock,
  doNotDisable,
  setDisabled
}) {
  const { getSelectedValue, validateSelection, selectValue } = variantHandler;
  const selectedValue = getSelectedValue(option);
  const t = useIntl();

  const selectOptionText = t('Select {optionName}', {
    optionName: option.name
  });

  const whenClicked = (value, option) => {
    selectValue(value, option);
    setDisabled(false);
  };
  return (
    <ButtonSelectWrapper className={cx(showValidationMessage && 'invalid')}>
      <Heading3 text={selectOptionText} />
      <div className="options-row">
        {option.values.map(value => {
          const validation = validateSelection(value, option);
          const disabled = validation !== 'valid';

          return (
            <ButtonWithLoading
              data-testid={option.name + value}
              key={option.name + value}
              onClick={() => whenClicked(value, option)}
              // disabled={disabled}
              // secondary={value !== selectedValue}
              className={cx(
                value === selectedValue && 'selected',
                disabled && 'disabled'
              )}
              aria-pressed={value === selectedValue}
            >
              <LargeParagraph text={value} />
            </ButtonWithLoading>
          );
        })}
      </div>
    </ButtonSelectWrapper>
  );
}

function DropdownVariantSelect({
  option,
  variantHandler,
  showValidationMessage,
  doNotDisable,
  setDisabled
}) {
  const { getSelectedValue, validateSelection, selectValue } = variantHandler;
  const t = useIntl();

  const selectOptionText = t('Select {optionName}', {
    optionName: option.name
  });

  const selectedValue = getSelectedValue(option);
  return (
    <div className={cx(dropdownStyles, showValidationMessage && 'invalid')}>
      <Heading3 text={selectOptionText} />
      <DropdownMenu>
        <DropdownMenuButton id={`option-${option.name}`}>
          {selectedValue
            ? selectedValue
            : t.rich('Select {option}', { option: option.name })}
          <Caret />
        </DropdownMenuButton>
        <DropdownMenuItems style={{ zIndex: 9999 }}>
          {option.values.map(value => {
            const validation = validateSelection(value, option);
            const disabled = validation !== 'valid';
            return (
              <DropdownMenuItem
                data-testid={value + option.name}
                key={value + option.name}
                disabled={disabled}
                onSelect={({ setIsOpen }) => {
                  selectValue(value, option);
                  setIsOpen(false);
                  setDisabled(false);
                }}
                style={{
                  opacity: validation === 'valid' ? 1 : 0.2,
                  cursor: validation === 'valid' ? 'cursor' : 'not-allowed'
                }}
              >
                {value}
                {selectedValue === value && <Check />}
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuItems>
      </DropdownMenu>
    </div>
  );
}

export const dropdownStyles = css`
  && {
    margin: 1em 0;
    [data-flight-dropdown] {
      background: ${theme.colors.lightgrey};
    }
    [data-flight-dropdown-button] {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 2px;
      border: none;
      height: 45px;
      font-size: ${theme.fontSizes.button};
      padding: 0 20px;
      color: ${theme.colors.black};
      background: ${theme.colors.transparent};
    }
    [data-flight-dropdown-open='true'] {
      [data-flight-dropdown-button] {
        border-radius: 2px 2px 0 0;
      }
    }
    [data-flight-dropdown-items] {
      width: 100%;
      margin-top: 10px;
      border-radius: 0 0 2px 2px;
      background: ${theme.colors.white};
      width: 100%;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    }

    [data-flight-dropdown-item] {
      display: flex;
      align-items: center;
      border: none;
      height: 45px;
      padding: 0.5rem 20px;
      font-size: ${theme.fontSizes.button};
      justify-content: space-between;

      :last-child {
        border-radius: 0 0 3px 3px;
      }

      svg {
        g g use {
          fill: ${theme.colors.black};
        }
      }

      &:hover {
        background: ${theme.colors.lightgrey};
        color: ${theme.colors.black};
      }
    }
    &.invalid [data-flight-dropdown-button] {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }
`;

import React from 'react';
import { theme } from '../../Theme';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import { LinkEvaluator } from '../../../utils/LinkEvaluator';
import { ReactComponent as Smartphone } from '../../../svg/RadneSmartphone.svg';
import { ReactComponent as Mail } from '../../../svg/RadneMail.svg';
import { hoverShakeStyle } from '../../ui/sharedStyles/Shake';

const ContactUsWrapper = styled('div')`
  width: 100%;
  display: flex;
  border-top: 1px solid ${theme.colors.border};
  border-bottom: 1px solid ${theme.colors.border};
  padding: 30px 0;

  a {
    width: fit-content;
    white-space: nowrap;
  }

  a + a {
    margin-left: 30px;
  }
`;

const LinkContentWrapper = styled('div')`
  display: flex;
  align-items: center;
  font-size: ${theme.fontSizes.button};
  color: ${theme.colors.black};
  width: fit-content;
  p {
    margin: 0 10px;
  }

  svg {
    height: 18px;
    width: auto;
  }
`;

export const ContactUs = () => {
  //TODO - create from content editor in a category
  return (
    <MaxWidth>
      <ContactUsWrapper>
        <LinkEvaluator link={'tel:+46855650690'}>
          <LinkContentWrapper>
            <Smartphone className={hoverShakeStyle} />
            <p>08-556 506 90 </p>
          </LinkContentWrapper>
        </LinkEvaluator>
        <LinkEvaluator link={'mailto:info@radne.se'}>
          <LinkContentWrapper>
            <Mail className={hoverShakeStyle} />
            <p>info@radne.se </p>
          </LinkContentWrapper>
        </LinkEvaluator>
      </ContactUsWrapper>
    </MaxWidth>
  );
};

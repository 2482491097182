import React from 'react';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import StockStatusIndicator from '../ProductPage/StockStatus/StockStatusIndicator';

export const StockStatusHolder = ({ product }) => {
  const stockStatus = useStockStatus(product);
  return (
    <>
      {stockStatus?.status ? (
        <StockStatusIndicator
          text={stockStatus?.text}
          status={stockStatus?.status}
        />
      ) : (
        <div
          className="no-stockstatus"
          style={{
            height: '15px'
          }}
        />
      )}
    </>
  );
};

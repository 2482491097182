import React from 'react';
import { theme } from '../../Theme';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import { ReactComponent as Radne } from '../../../svg/Radne.svg';
import { DynamicCategoryContentRenderer } from '../../ContentComponents/DynamicContentRenderer';
import { footerComponents } from '../../ContentComponents/ContentComponents';

const FooterLinksWrapper = styled('div')`
  display: flex;
  padding-top: 30px;
  width: 100%;

  > svg {
    align-self: flex-start;
    width: 234px;
    height: auto;
    margin-bottom: 30px;
  }

  ${theme.below.lg} {
    flex-direction: column;
  }
`;

const ColumnsWrapper = styled('div')`
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: -3px;

  .dynamic-wrapper {
    display: flex;
    flex-wrap: wrap;
    > div {
      ${theme.below.lg} {
        padding: 0 1rem 1rem 0;
      }
    }
  }

  ${theme.below.lg} {
    justify-content: flex-start;
    margin: 0;
  }
`;

export const FooterLinks = () => {
  return (
    <MaxWidth>
      <FooterLinksWrapper>
        <Radne />
        <ColumnsWrapper className="here">
          <DynamicCategoryContentRenderer
            categoryId={315}
            rendererComponents={footerComponents}
          />
        </ColumnsWrapper>
      </FooterLinksWrapper>
    </MaxWidth>
  );
};

import React from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { ReactComponent as ChevronLeft } from '../../../svg/ChevronLeft.svg';
import { ReactComponent as ChevronRight } from '../../../svg/ChevronRight.svg';
import { theme } from '../../Theme';

const SliderButtonWrapper = styled('div')`
  height: 58px;
  width: 80px;
  background: ${theme.colors.red};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 24px;
    width: 14px;
    color: ${theme.colors.white};
  }

  &.left {
    border-radius: 0 29px 29px 0;

    svg {
      margin-right: 14px;
    }
  }

  &.right {
    border-radius: 29px 0 0 29px;
    svg {
      margin-left: 14px;
    }
  }

  :hover,
  :active {
    opacity: 0.8;
  }
`;

export const SliderButton = ({ direction, callback }) => {
  return (
    <SliderButtonWrapper
      className={cx('slider-button', direction)}
      onClick={callback}
    >
      {direction === 'left' && <ChevronLeft />}
      {direction === 'right' && <ChevronRight />}
    </SliderButtonWrapper>
  );
};

import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { ReactComponent as Cart } from '../../../svg/Cart.svg';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { useAddToCart } from '../../../hooks/useAddToCart';
import { QuickBuyContext } from './QuickBuyContext';
import { useTrackProductAdd } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';

const QuickBuyButtonWrapper = styled('button')`
  background: ${theme.colors.red};
  border: 0;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: white;
    width: 18px;
    height: 18px;
    path {
      stroke: white;
    }
  }
`;

/**
 * The QuickBuyButton function renders a button that when clicked, either adds product to cart or displays the QuickBuyFlyout component.
 *
 * @param {object} product Pass the product details
 * @param {number} qty Set the default quantity of the product
 *
 * @return A button that can add the product to the cart
 *
 * @docauthor Anders Zetterström @ R3
 */
export const QuickBuyButton = ({ product, qty = 1 }) => {
  const trackAddProductVariant = useTrackProductAdd(product);

  const trackAddToCart = () => {
    trackAddProductVariant(product, qty);
  };
  const { addProduct } = useAddToCart(trackAddToCart);
  const { setQuickBuyProduct, setQuickBuyQty } = useContext(QuickBuyContext);

  if (!product?.stockStatus?.buyable) {
    return null;
  }

  return (
    <div className="quick-buy-button">
      {product?.variants?.values?.length > 0 || product?.isPackage ? (
        <DrawerTrigger preventOverflow={true} id="quick-buy-drawer">
          {drawer => (
            <QuickBuyButtonWrapper
              onClick={e => {
                // Disables link click
                e.preventDefault();
                setQuickBuyProduct(product);
                setQuickBuyQty(qty);
                drawer.showTarget();
              }}
            >
              <Cart />
            </QuickBuyButtonWrapper>
          )}
        </DrawerTrigger>
      ) : (
        <QuickBuyButtonWrapper
          onClick={e => {
            // Disables link click
            e.preventDefault();
            addProduct(product, qty);
          }}
        >
          <Cart />
        </QuickBuyButtonWrapper>
      )}
    </div>
  );
};

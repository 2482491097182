import t from '@jetshop/intl';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { css, cx } from 'linaria';
import React, { useContext, useRef, useEffect } from 'react';
import StockStatusIndicator from '../ProductPage/StockStatus/StockStatusIndicator';
import { theme } from '../Theme';
import { StyledPrice } from '../ui/StyledPrice';
import { Heading3 } from '../ui/text/Heading3';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import { QuickBuyButton } from '../Layout/QuickBuy/QuickBuyButton';
import { PromoCardContext } from './PromoCardContext';
import { StockStatusHolder } from '../ui/StockStatusHolder';

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: ${theme.colors.lightgrey};
    border: 1px solid ${theme.colors.lightgrey};
  }

  .product-card-image {
    mix-blend-mode: darken;
  }

  .product-card-detail {
    padding: 0.75em;
    line-height: 1.35;
    border-top: 1px solid ${theme.colors.lightgrey};

    .heading-3 {
      text-overflow: ellipsis;
      /* white-space: nowrap; */
      height: 36px;
      overflow: hidden;
      ${theme.below.lg} {
        font-size: 16px;
      }
    }
  }
  .package-badge {
    background: black;
    color: white;
    font-size: 14px;
    padding: 3px 6px;
  }
  .price-package-wrapper {
    display: flex;
    flex-direction: column;

    > div {
      align-items: end;
    }

    .stockstatus-wrapper,
    .no-stockstatus {
      margin-top: -2px;
    }

    .price {
      font-size: ${theme.fontSizes.heading3};
      color: ${theme.colors.darkred};
    }
    .package-price-label {
      font-size: 12px;
      color: grey;
      margin-left: 5px;
    }

    [data-flight-price] {
      ${theme.below.lg} {
        align-items: center;
      }
    }
    .new-price {
      ${theme.below.lg} {
        font-size: 18px;
      }
    }
    .old-price {
      ${theme.below.lg} {
        line-height: 1;
      }
    }
  }

  .badge-top-right {
    top: 3em;
  }
  .bottom-row {
    display: flex;
    justify-content: start;
    position: relative;
    ${theme.below.lg} {
      padding-bottom: 5px;
    }

    .buy-wrapper {
      position: absolute;
      left: auto;
      right: 0;
      bottom: 0;
      top: auto;
      width: fit-content;
    }
  }

  [data-flight-image-badges] {
    .discount-tag {
      width: 150px;
      height: 20px;
      transform: skew(-42deg);
      position: relative;
      left: 12px;
      top: 12px;
      white-space: nowrap;
      background-color: #d4181a;
    }
    .black {
      background-color: black;
    }
    .discount-tag span {
      color: white;
      font-size: 16px;
      transform: skew(42deg) !important;
    }
    .small {
      width: 70px;
    }
    .medium {
      width: 150px;
    }
    .large {
      width: 250px;
    }
  }
`;

export function ProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'div',
  children,
  loadImageEagerly = false,
  ...linkProps
}) {
  const cardRef = useRef();

  const hasImages = product?.images && product?.images.length > 0;
  const Tag = as;
  let badges = [...(product?.badges || [])];
  product?.isPackage &&
    badges?.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  const { setCardHeight } = useContext(PromoCardContext);

  useEffect(() => {
    const updateHeight = () => {
      setCardHeight(cardRef?.current?.clientHeight);
    };

    window?.addEventListener('resize', updateHeight);
    return () => {
      window?.removeEventListener('resize', updateHeight);
    };
  }, []);

  useEffect(() => {
    if (setCardHeight && cardRef?.current?.clientHeight !== undefined) {
      // used to set correct height on Promo Cards
      setCardHeight(cardRef?.current?.clientHeight);
    }
  }, [cardRef?.current?.clientHeight, setCardHeight]);

  return (
    <Tag
      className={cx('product-card', wrapperStyling, className)}
      data-testid="product"
      data-product-card
      ref={cardRef}
    >
      <ProductLink
        product={product}
        {...linkProps}
        style={{
          margin: '0 0.5em 1em'
        }}
      >
        {hasImages ? (
          <Image
            className="product-card-image"
            sizes={imageSizes}
            aspect={imageAspect}
            alt={product?.images?.[0]?.alt}
            src={product?.images?.[0]?.url}
            modifiedDate={product?.images?.[0]?.modifiedDate}
            badges={<Badges badges={badges} />}
            critical={loadImageEagerly}
          ></Image>
        ) : (
          <Image src={transparentDataImg} />
        )}
        <section className="product-card-detail">
          <header>
            <Heading3 text={product?.name} />
          </header>
          <div className="price-package-wrapper">
            <StyledPrice
              price={product?.price}
              previousPrice={product?.previousPrice}
            />

            <div className="bottom-row">
              {product?.stockStatus?.text && (
                <StockStatusHolder product={product} />
              )}

              <div className="buy-wrapper">
                <QuickBuyButton product={product} />
              </div>
            </div>
          </div>
        </section>
      </ProductLink>
    </Tag>
  );
}

import React from 'react';
import { styled } from 'linaria/react';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import Image from '@jetshop/ui/Image';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';

const MenuContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  ${theme.below.lg} {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-right: -20px;
    .menu-content-inner::-webkit-scrollbar {
      display: none;
    }

    .menu-content-item-wrapper:last-of-type {
      margin-right: 20px;
    }
  }

  .menu-content-inner {
    display: flex;
    ${theme.below.lg} {
      overflow: scroll;
      a {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: none;
      }
    }
  }

  .menu-content-item-wrapper + .menu-content-item-wrapper {
    margin-left: 20px;
    ${theme.below.lg} {
      margin-left: 11px;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: ${theme.colors.black};
    padding-bottom: 20px;
    ${theme.below.lg} {
      display: none;
    }
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: ${theme.colors.black};
    padding-top: 5px;
  }

  a:hover {
    text-decoration: none !important;
  }
`;

/**
 * Finds if Category has subcategory named "Menu content" and is level 2 and returns id of that subcategory.
 * @param {object} category - category object
 * @return {int} Menu content category id
 */
export const findMenuContentCategoryId = category => {
  return category?.subcategories?.find(
    subcat =>
      subcat?.name?.toLowerCase() === 'menu content' && subcat.level === 2
  )?.id;
};

export const MenuContent = ({ title, children }) => {
  return (
    <MenuContentWrapper className="menu-content-wrapper">
      {title && <h3>{title?.value}</h3>}
      <div className="menu-content-inner">{children}</div>
    </MenuContentWrapper>
  );
};

export const MenuContentItemWrapper = styled('div')`
  min-width: 95px;
  max-width: 95px;
  position: relative;
  color: black;

  ${theme.below.lg} {
    min-width: 112px;
    max-width: 112px;
  }

  .link-evaluator {
    padding: 0px !important;
  }
`;

export const MenuContentItem = ({ image, link, text, closeNav }) => {
  const sizes = [1 / 3, 1 / 3, 1 / 5, 1 / 8];
  return (
    <MenuContentItemWrapper className="menu-content-item-wrapper">
      <LinkEvaluator link={link} linkClickCallback={closeNav?.value}>
        <Above breakpoint="lg">
          {matches => (
            <>
              <Image
                src={image.value}
                aspect={matches ? '95:112' : '112:112'}
                sizes={sizes}
                cover
              />
              <p>{text?.value}</p>
            </>
          )}
        </Above>
      </LinkEvaluator>
    </MenuContentItemWrapper>
  );
};

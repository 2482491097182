/**
 * The PlaceholderChildComponent function is a component that renders nothing.
 * It's used to create elements for Conten Editor components that doesn´t need rendering.
 *
 *
 * @return Null
 *
 * @docauthor Anders Zetterström @ R3
 */
export const PlaceholderChildComponent = () => {
  return null;
};

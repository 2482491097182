import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import { LinkEvaluator } from '../../../utils/LinkEvaluator';
import { Paragraph } from '../../ui/text/Paragraph';
import { theme } from '../../Theme';

const CompanyInfoWrapper = styled('div')`
  width: 100%;
  padding: 30px 0 40px 0;

  > * + * {
    margin-top: 20px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;

    ${theme.above.md} {
      > * {
        width: fit-content;
      }
      > * + * {
        margin-left: 3px;
      }
    }

    .paragraph {
      opacity: 1;
    }
  }

  .paragraph {
    opacity: 0.5;
  }
`;

export const CompanyInfo = () => {
  return (
    <MaxWidth>
      <CompanyInfoWrapper>
        <div className="row">
          <LinkEvaluator link={'https://goo.gl/maps/zx2EkL4LthikBrF9A'}>
            <Paragraph text={'Radne AB, Markörgatan 2, 138 44 Handen.'} />
          </LinkEvaluator>
          <Paragraph text={'Organisationsnummer: 556289-7834'} />
        </div>
        <Paragraph text={'© 2022 Radne AB. All rights reserved.'} />
      </CompanyInfoWrapper>
    </MaxWidth>
  );
};

// import ChannelContext from '@jetshop/core/components/ChannelContext';
// import React, { useContext, useEffect } from 'react';
// import { styled } from 'linaria/react';
//import { useScript } from '../hooks/useScript';

// const KlarnaWrapper = styled('div')`
//   width: 100%;
//   margin: 0 !important;

//   > * {
//     width: 100%;
//   }
// `;

const KlarnaPaymentInfo = ({ product }) => {
  // TODO - change to Klarna Radne key in useScript
  return null;
  // const { selectedChannel } = useContext(ChannelContext);

  // const [loaded, error] = useScript(
  //   'https://eu-library.klarnaservices.com/lib.js',
  //   true
  // );

  // useEffect(() => {
  //   if (loaded) {
  //     if (typeof window !== 'undefined') {
  //       window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
  //     }
  //   }
  // }, [loaded]);

  // if (!loaded || error) return null;

  // return (
  //   <KlarnaWrapper className="misc-item">
  //     <klarna-placement
  //       data-key="credit-promotion-small"
  //       data-locale={selectedChannel.language.culture}
  //       data-purchase-amount={product ? product.price.incVat : '1000kr'}
  //     >
  //       <link
  //         rel="stylesheet"
  //         href="https://x.klarnacdn.net/ui/fonts/v1.3/fonts.css"
  //       />
  //     </klarna-placement>
  //   </KlarnaWrapper>
  // );
};
export default KlarnaPaymentInfo;

import React, { useState } from 'react';

const PromoCardContext = React.createContext();

const PromoCardProvider = ({ children }) => {
  const [height, setHeight] = useState(null);

  const setCardHeight = value => {
    setHeight(value);
  };

  return (
    <PromoCardContext.Provider
      value={{
        setCardHeight,
        cardHeight: height
      }}
    >
      {children}
    </PromoCardContext.Provider>
  );
};

export { PromoCardProvider, PromoCardContext };

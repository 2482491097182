import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as Box } from '../../svg/Box-arrow.svg';
import { ReactComponent as Global } from '../../svg/Global.svg';
import { ReactComponent as Truck } from '../../svg/Fast_shipping.svg';
import { Paragraph } from '../ui/text/Paragraph';

const UspsWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .usp-item {
    min-width: 50%;
  }
`;

export const Usps = ({ children }) => {
  return (
    <UspsWrapper className="usps-wrapper">
      {/* {uspArray?.map(usp => {
        return <UspItem icon={usp?.icon} text={usp?.text} />;
      })} */}
      {children}
    </UspsWrapper>
  );
};

const UspItemWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding: 8px 0;
  svg {
    width: auto;
    height: 18px;
    margin-right: 1rem;
  }
  margin-bottom: 8px;
`;

export const UspItem = ({ icon, text }) => {
  const iconSwitch = iconName => {
    switch (iconName) {
      case 'BOX':
        return <Box />;
      case 'GLOBE':
        return <Global />;
      case 'TRUCK':
        return <Truck />;

      default:
        return null;
    }
  };

  return (
    <UspItemWrapper className="usp-item">
      {iconSwitch(icon?.value)}
      <Paragraph text={text?.value} weight={'bold'} />
    </UspItemWrapper>
  );
};

import CampaignBarQuery from './components/Layout/Header/CampaignBarQuery.gql';
import homeCategoriesQuery from './components/Layout/Header/Categories/HomeCategoriesQuery.gql';
import CategoryContentQuery from './components/ContentComponents/CategoryContentQuery.gql';

export const persistedQueries = [
  {
    query: homeCategoriesQuery,
    variables: { levels: 1 }
  },
  {
    query: CampaignBarQuery,
    variables: {
      id: 173
    }
  },
  {
    query: CategoryContentQuery,
    variables: {
      id: 271
    }
  },
  {
    query: CategoryContentQuery,
    variables: {
      id: 315
    }
  }
];

import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t, { useIntl } from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Price } from '@jetshop/ui/Price';
import { theme } from '../Theme';
import { baseStyles } from '../ui/Button';
import CartItem from './CartItem';
import FreeShipping from './FreeShipping';
import cartQuery from './queries/cartQuery.gql';
import { ReactComponent as Close } from '../../svg/Close.svg';
import { DynamicCategoryContentRenderer } from '../ContentComponents/DynamicContentRenderer';
import { UspItem, Usps } from '../ProductPage/Usps';
import { PaymentRow, PaymentRowItem } from './PaymentRow';
import { useAddwish } from '@jetshop/flight-addwish';
import ProductRecommendations from '../ProductRecommendations';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const Flyout = styled('div')`
  background: white;
  color: ${theme.colors.black};
  overflow: hidden;
  ${theme.above.lg} {
    position: absolute;
    z-index: 999;
    right: 0;
    top: 0;
    width: 480px;
    max-width: 100%;
  }
  .upper {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 65vh;
    padding: 0 46px;

    .heading-3 {
      margin-bottom: 10px;
    }

    ${theme.above.lg} {
      max-height: 70vh;
    }
    ${theme.below.lg} {
      padding: 0 16px;
      .heading-3 {
        font-size: 16px;
      }
    }
    ${theme.below.xs} {
      max-height: 55vh;
    }
  }
  .lower {
    padding: 0 46px;
    border-top: 1px solid ${theme.colors.border};
    ${theme.below.lg} {
      padding: 0 16px;
      z-index: 1;
      position: sticky;
      bottom: 20px;
    }
    ${theme.below.xs} {
      bottom: 0;
    }
  }
  .shared-swiper {
    margin-left: 0;
    a {
      margin: 0 !important;
      margin-right: 10px !important;
    }
  }
`;

const LightText = styled('span')`
  color: #828282;
  font-size: 0.875rem;
  font-weight: normal;
`;

export const Header = styled('header')`
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  height: 60px;
  padding: 1rem 0;
  border-bottom: 1px solid #e8e8e8;

  ${theme.above.lg} {
    height: 76px;
    padding: 0;
  }

  h2 {
    font-weight: 600;
  }

  ${LightText} {
    display: block;
  }

  .closebutton {
    background-color: transparent;
    color: ${theme.colors.red};
    margin-right: 10px;
  }
`;

const CartItems = styled('section')``;

const Summary = styled('section')`
  background: #fff;

  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 1.2em;
  }

  .cart-total {
    border-top: 1px solid ${theme.colors.border};
    border-bottom: 1px solid ${theme.colors.border};

    div {
      padding: 0 18px;
    }

    > * + * {
      margin-top: 0.5em;
    }

    .total,
    .vat {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &.checkout {
    padding-top: 30px;
    padding: 0;

    ${theme.above.lg} {
      padding: 0;
    }

    a,
    button {
      height: 41px;
      margin-bottom: 10px;
    }
  }

  .cartinformation {
    border-bottom: 1px solid ${theme.colors.border};
    padding: 10px 0;

    h2 {
      padding: 0;
      font-size: 18px;
      font-weight: 700;
    }

    > * {
      padding: 10px 0;
    }
  }

  .total {
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0;
    color: ${theme.colors.black};
    border-bottom: 1px solid ${theme.colors.border};

    .price-wrapper {
      padding: 0;
      display: flex;
      justify-content: space-between;
      .price {
        color: ${theme.colors.red};
      }
    }
  }

  .discounts {
    h2 {
      font-size: 16px;
      font-weight: 500;
    }
    .discounts-wrapper {
      padding: 0;
      display: flex;
      justify-content: space-between;
    }
  }

  .shipping {
    padding: 0;
    display: flex;
    justify-content: space-between;
    color: ${theme.colors.black};
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    h2 {
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const Checkout = styled.a`
  ${baseStyles};
  width: 100%;
  background: ${theme.colors.red};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  &&:hover {
    color: white;
    text-decoration: none;
  }
`;

const ContinueShoppingButton = styled(Checkout)`
  background: transparent;
  color: ${theme.colors.black};
  border: 1px solid ${theme.colors.black};
  margin-top: 10px;
  ${theme.above.lg} {
    margin-top: 0;
  }
  &:hover,
  &:active {
    background: black;
    color: white !important;
    text-decoration: none !important;
    cursor: pointer;
  }
`;

const UspAndPaymentWrapper = styled('div')`
  .usps-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    ${theme.above.lg} {
      padding: 20px 0px;
    }
  }

  .usp-item {
    min-width: unset;
    padding: 0;
    p {
      font-size: 11px;
      line-height: 12px;
      font-weight: 400;
    }
  }
`;

const Recommendations = styled('div')`
  margin-top: 40px;
  margin-left: 0;
  > div {
    overflow: hidden;
    padding: 0;
  }
`;

const CartFlyoutView = ({ result, modal, ...rest }) => {
  const items = result?.data?.cart?.items ?? [];
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const discounts = result?.data?.cart?.aggregatedDiscounts ?? [];
  const track = useTracker();

  const { selectedChannel } = useContext(ChannelContext);

  const translate = useIntl();
  const helloRetailTitle = translate('Products purchased together');

  const findPurchasedTogetheFirst = () => {
    switch (selectedChannel?.id) {
      //Sweden
      case 1:
        //testkey
        // return 'k6320475bc8a88762728e3589';
        return 'k5e30507e7c9ad84cc6405d6b';
      //Finland
      case 2:
        return 'k609e8b364c40ff69efebe45a';
      //Danmark
      case 3:
        return null;
      //.com
      default:
        return 'k5ef079d451639f36f1843d3a';
    }
  };
  const purchasedTogetheFirst = findPurchasedTogetheFirst();

  const findPurchasedTogetheSecond = () => {
    switch (selectedChannel?.id) {
      //Sweden
      case 1:
        //testkey
        // return 'k6320475cc8a88762728e358f';
        return 'k5e30507e7c9ad84cc6405d65';
      //Finland
      case 2:
        return 'k609e8b364c40ff69efebe488';
      //Danmark
      case 3:
        return 'k609e8b2d4c40ff69efebe0c3';
      //.com
      default:
        return 'k5ef079d451639f36f1843d49';
    }
  };
  const purchasedTogetheSecond = findPurchasedTogetheSecond();
  const { addWishResponseBoxItems } = useAddwish({
    boxes: {
      [purchasedTogetheFirst]: {
        urls: items.map(
          item =>
            `${document.location.protocol}//${document.location.hostname}${item.product.primaryRoute.path}`
        )
      },
      [purchasedTogetheSecond]: {
        urls: items.map(
          item =>
            `${document.location.protocol}//${document.location.hostname}${item.product.primaryRoute.path}`
        )
      }
    },
    pageLoaded: true
  });

  if (items.length === 0) {
    return (
      <Flyout {...rest}>
        <div style={{ padding: '2em' }}>{t('No items in cart.')}</div>
      </Flyout>
    );
  }

  return (
    <Flyout {...rest}>
      <Header>
        <button onClick={modal.hideTarget} className="closebutton">
          <Close />
        </button>
      </Header>
      <div className="upper">
        <CartItems>
          {items.map(item => (
            <CartItem item={item} key={item.id} />
          ))}
        </CartItems>
        <Recommendations>
          <ProductRecommendations
            title={helloRetailTitle}
            items={addWishResponseBoxItems[purchasedTogetheFirst].items}
            cartPage={true}
          />
          <ProductRecommendations
            title={helloRetailTitle}
            items={addWishResponseBoxItems[purchasedTogetheSecond].items}
            cartPage={true}
          />
        </Recommendations>
      </div>
      <div className="lower">
        <Summary>
          <>
            {discounts.length > 0 && (
              <div className="cartinformation">
                <div className="discounts">
                  {discounts.map(discount => {
                    return (
                      <>
                        <h2>{t('Discount')}</h2>
                        <div className="discounts-wrapper">
                          <label>{discount.name}</label>
                          <Price
                            price={discount.value}
                            style={{ display: 'inline' }}
                            negative
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="total">
              <div className="shipping-wrapper">
                <FreeShipping
                  className="shipping"
                  cartTotal={result.data.cart.productTotal}
                />
              </div>
              <div className="price-wrapper">
                <label>{t('Total')}</label>
                <Price price={result.data.cart.productTotal} />
              </div>
            </div>
          </>
        </Summary>
        <Summary className="checkout">
          {checkoutUrl && (
            <Checkout
              data-testid="checkout-button"
              href={checkoutUrl}
              onClick={event => {
                event.preventDefault();
                track(
                  trackCartCheckoutEvent({
                    cart: result.data.cart,
                    callback: () => {
                      window.location = checkoutUrl;
                    }
                  })
                );
              }}
            >
              {t('Check out')}
            </Checkout>
          )}
          <ContinueShoppingButton onClick={modal.hideTarget}>
            {t('Continue shopping')}
          </ContinueShoppingButton>
          <UspAndPaymentWrapper>
            <DynamicCategoryContentRenderer
              categoryId={272}
              rendererComponents={{ USPROW: Usps, USPITEM: UspItem }}
            />
            <DynamicCategoryContentRenderer
              categoryId={272}
              rendererComponents={{
                PAYMENTROW: PaymentRow,
                PAYMENTROWITEM: PaymentRowItem
              }}
            />
          </UspAndPaymentWrapper>
        </Summary>
      </div>
    </Flyout>
  );
};

const CartFlyout = props => {
  return (
    <CartProvider query={cartQuery}>
      {result =>
        result.data && result.data.cart ? (
          <Above breakpoint="lg">
            {matches => (
              <DrawerTarget id="cart-drawer">
                {drawer => (
                  <Drawer
                    isOpen={drawer.isOpen}
                    right
                    size={matches ? 480 : 332}
                  >
                    <CartFlyoutView modal={drawer} result={result} {...props} />
                  </Drawer>
                )}
              </DrawerTarget>
            )}
          </Above>
        ) : null
      }
    </CartProvider>
  );
};

export default CartFlyout;

import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { Heading4 } from '../../ui/text/Heading4';

const B2bBannerWrapper = styled('div')`
  width: 100%;
  background: ${theme.colors.darkred};

  padding: 5px 20px;
  .heading-4 {
    color: white;
    text-align: center;
    font-weight: 600;
  }
`;

export const B2bBanner = () => {
  const bannerText = 'Radne Dealer Portal';
  return (
    <B2bBannerWrapper>
      <Heading4 text={bannerText} />
    </B2bBannerWrapper>
  );
};

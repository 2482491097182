import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const RedTiltWrapper = styled('div')`
  width: fit-content;
  display: flex;
  margin: 0 calc(${props => props.height}px / 2);
  padding: 0 calc(${props => props.height}px / 2);
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: fit-content;
  background: ${theme.colors.transparent};

  .content {
    z-index: 1;
    color: white;
  }
`;

const RedTiltBackground = styled('div')`
  background: ${theme.colors.darkred};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: skew(-45deg);
`;

/**
 * The RedTilt function is a React component that creates a red tilt effect and presents its children in it.
 *
 *
 * @param children  children of this component
 *
 * @return A div element with a child of the children prop
 *
 * @docauthor Anders Zetterström @ R3
 */
export const RedTilt = ({ children }) => {
  const [height, setHeight] = useState(0);
  const tiltRef = useRef();

  useEffect(() => {
    setHeight(tiltRef?.current?.clientHeight);
  }, []);

  return (
    <RedTiltWrapper
      className={'red-tilt-wrapper'}
      ref={tiltRef}
      height={height}
    >
      <RedTiltBackground />

      <div className="content">{children}</div>
    </RedTiltWrapper>
  );
};

import { useIntl } from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import ModalContext from '@jetshop/ui/Modal/ModalContext';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import { ReactComponent as SearchIcon } from '../../../svg/Search.svg';
import CartButton from '../../Cart/CartButton';
import useHeaderHeight from '../../hooks/useHeaderHeight';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { theme } from '../../Theme';
import { ReactComponent as Global } from '../../../svg/Global.svg';
import useChannelCheck from '../../hooks/useChannelCheck';
import { SearchField } from '@jetshop/ui/Search';
import autocompleteQuery from './AutocompleteQuery.gql';

const IconItems = styled('div')`
  display: flex;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;
export const IconItem = styled('div')`
  button,
  svg {
    background: transparent;
  }
`;

const CountryFlag = styled('img')`
  height: 24px;
  width: 24px;
`;

export const SearchFieldWrapper = styled('div')`
  position: relative;
  display: flex;
  height: 41px;
  width: 368px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.border};

  input {
    outline: none;
    margin-left: 50px;
  }

  svg {
    position: absolute;
    top: 6px;
    left: 16px;
    &:hover {
      cursor: pointer;
    }
  }

  input {
    width: 100%;
    border: none;
  }

  [data-flight-searchfield] {
    margin: 0 0 0 50px;
    width: 80%;
    > div {
      width: 100%;
    }
  }

  [data-flight-searchfield-cancel] {
    display: none;
  }

  &.b2b {
    input {
      outline: none;
      margin-left: 0;
    }
    [data-flight-searchfield-flyout] {
      width: 100vw;
      top: 102px;
      left: auto;
      right: -1px;
      margin-right: -52%;
      padding: 40px;
    }

    h2 {
      font-family: ${theme.fonts.primary};
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
      margin-bottom: 2px;
    }

    li,
    a {
      font-family: ${theme.fonts.primary};
      font-size: 14px;
      line-height: 22px;
      margin-left: 10px;
      color: #333 !important;
      text-decoration: none;
      letter-spacing: 0.01em;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export default function IconRow({ searchOpen, setSearchOpen }) {
  const topPosition = useHeaderHeight();
  const { modals } = useContext(ModalContext);
  const translate = useIntl();
  const searchPlaceholder = translate('Search for products');
  const { isB2B } = useChannelCheck();

  return (
    <ChannelContext.Consumer>
      {({ selectedChannel }) => (
        <IconItems>
          <Above breakpoint="lg">
            {matches =>
              matches &&
              (searchOpen ? (
                isB2B ? (
                  <SearchFieldWrapper className="b2b">
                    <SearchIcon
                      className="close-icon"
                      onClick={() => {
                        modals && modals[0]?.hideTarget();
                        setSearchOpen(!searchOpen);
                      }}
                    />
                    <SearchField
                      onCancel={() => null}
                      focusOnLoad={false}
                      placeholder="Sök efter produkt"
                      autocompleteQuery={autocompleteQuery}
                      onSubmit={() => setSearchOpen(false)}
                      onClick={() => setSearchOpen(false)}
                    />
                  </SearchFieldWrapper>
                ) : (
                  <SearchFieldWrapper id="hr-search">
                    <SearchIcon
                      className="close-icon"
                      onClick={() => {
                        modals && modals[0]?.hideTarget();
                        setSearchOpen(!searchOpen);
                      }}
                    />
                    <input placeholder={searchPlaceholder} />
                  </SearchFieldWrapper>
                )
              ) : (
                <IconItem>
                  <button
                    data-testid="search-button"
                    id="search-button"
                    onClick={() => setSearchOpen(!searchOpen)}
                  >
                    <SearchIcon />
                  </button>
                </IconItem>
              ))
            }
          </Above>
          <IconItem>
            <CartButton modals={modals} />
          </IconItem>
          <IconItem
            onClick={() => {
              // Closes search if open
              setSearchOpen(false);
            }}
          />
          <IconItem>
            <DrawerTrigger
              preventOverflow={true}
              id="channel-drawer"
              coverStyles={{ top: topPosition }}
            >
              {drawer => (
                <button
                  data-testid="channel-button"
                  id="channel-button"
                  onClick={
                    drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                  }
                >
                  {selectedChannel.id !== 4 && selectedChannel.id !== 7 ? (
                    <CountryFlag
                      src={`https://countryflags.jetshop.io/${selectedChannel.country.code}/flat/32.png`}
                      alt="Country Flag"
                      width="16"
                      height="12"
                    />
                  ) : (
                    <Global />
                  )}
                </button>
              )}
            </DrawerTrigger>
          </IconItem>
        </IconItems>
      )}
    </ChannelContext.Consumer>
  );
}

import React from 'react';
import { theme } from '../../Theme';
import { cx, css } from 'linaria';
import { fontWeightsStyles } from '../sharedStyles/FontWeightStyles';

export const jumboStyles = css`
  font-size: ${theme.fontSizes.jumbo};
  line-height: ${theme.lineHeights.jumbo};
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colors.black};
`;

/**
 * The HeadingJumbo function is a component that displays text in a large font.
 *
 *
 * @param {String}weight Determine which font weight to use (select classnames from fontWeightsStyles)
 * @param {String}text Text to be presented in component
 *
 * @return A div element with the classname of 'heading-jumbo', and the fontweightsstyles, jumbostyles, and weight classes
 *
 * @docauthor Anders Zetterström @ R3
 */
export const HeadingJumbo = ({ weight, text }) => {
  return (
    <div
      className={cx('heading-jumbo', jumboStyles, fontWeightsStyles, weight)}
      dangerouslySetInnerHTML={{
        __html: text
      }}
    />
  );
};

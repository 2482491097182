import React from 'react';
import { styled } from 'linaria/react';
import { ContentPlacer } from './ContentPlacer';
import { cx } from 'linaria';
import { Heading4 } from '../ui/text/Heading4';
import { Heading2 } from '../ui/text/Heading2';
import { Heading1 } from '../ui/text/Heading1';

const BoxContentWrapper = styled('div')`
  a {
    text-decoration: none;
  }

  .heading-jumbo,
  .heading-1,
  .heading-2,
  .heading-4 {
    color: ${props => props.color};
  }

  .heading-4 {
    margin-bottom: 14px;
  }

  .buttons-row {
    display: flex;
    width: 100%;
    margin-top: 25px;

    > * + * {
      margin-left: 10px;
    }
  }

  &.ALIGN_LEFT {
    text-align: left;

    .buttons-row {
      justify-content: flex-start;
    }
  }
  &.ALIGN_CENTER {
    text-align: center;

    .buttons-row {
      justify-content: center;
    }
  }

  &.ALIGN_RIGHT {
    text-align: right;

    .buttons-row {
      justify-content: flex-end;
    }
  }
`;

export const BoxContent = ({
  verticalPosition,
  horizontalPosition,
  color,
  preHeader,
  header,
  textAlignment,
  buttonChildren,
  tintLayer,
  forType,
  isH1
}) => {
  const isHero = forType.toLowerCase() === 'hero';

  return (
    <ContentPlacer
      verticalPosition={verticalPosition}
      horizontalPosition={horizontalPosition}
      tintLayer={tintLayer}
    >
      <BoxContentWrapper
        color={color?.value ?? '#fff'}
        className={cx(forType, textAlignment?.value)}
      >
        {preHeader?.value && <Heading4 text={preHeader?.value} />}
        {header?.value && (
          <>
            {isH1?.value ? (
              <Heading1 text={header?.value} fontSize={'40px'} />
            ) : (
              <Heading2
                text={header?.value}
                fontSize={isHero ? '40px' : '30px'}
              />
            )}
          </>
        )}

        {buttonChildren && <div className="buttons-row">{buttonChildren}</div>}
      </BoxContentWrapper>
    </ContentPlacer>
  );
};

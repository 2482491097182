import { Helmet } from 'react-helmet-async';
import React from 'react';

/**
 * The FaviconSelector function is a React component that renders the appropriate
 * favicon. It takes in one parameter:
 * faviconFolderPath which are a string that specify the path to the folder containing
 * all of our favicons.
 *
 * @param {string} faviconFolderPath the path to the favicon folder
 *
 * @return A helmet component that contains the following tags:
 *
 * @docauthor Anders Zetterström
 */
export const FaviconSelector = ({ faviconFolderPath }) => {
  const themeColor = '#d4181a';

  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${faviconFolderPath}/apple-touch-icon.png?v=2`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${faviconFolderPath}/favicon-32x32.png?v=1`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${faviconFolderPath}/favicon-16x16.png?v=1`}
      />
      <link rel="manifest" href={`${faviconFolderPath}/site.webmanifest?v=1`} />
      <link
        rel="mask-icon"
        href={`${faviconFolderPath}/safari-pinned-tab.svg?v=1`}
        color={themeColor}
      />
      <link rel="shortcut icon" href={`${faviconFolderPath}/favicon.ico?v=1`} />
      <meta name="apple-mobile-web-app-title" content="Radne" />
      <meta name="application-name" content="Radne" />
      <meta name="msapplication-TileColor" content={themeColor} />
      <meta name="theme-color" content={themeColor} />
    </Helmet>
  );
};

import React from 'react';
import PriceComponent from './Price';

/**
 * The PushPrice function is a React component that presents price with percentage discrount tag.
 *
 *
 * @param props  price props
 *
 * @return Price element
 *
 * @docauthor Anders Zetterström @ R3
 */
export const PushPrice = props => {
  return <PriceComponent usePushStyle={true} {...props} />;
};

/**
 * The PushPrice function is a React component that presents price.
 *
 *
 * @param props  price props
 *
 * @return Price element
 *
 * @docauthor Anders Zetterström @ R3
 */
export const StyledPrice = props => {
  return <PriceComponent {...props} />;
};

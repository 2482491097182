import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { usePrice } from '@jetshop/core/hooks/usePrice';
import { theme } from '../Theme';
import { RedTilt } from './RedTilt';
import ChannelContext from '@jetshop/core/components/ChannelContext';

var __rest =
  (this && this.__rest) ||
  function(s, e) {
    var t = {};
    for (var p in s)
      if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === 'function')
      for (var i = 0, bp = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (
          e.indexOf(bp[i]) < 0 &&
          Object.prototype.propertyIsEnumerable.call(s, bp[i])
        )
          t[bp[i]] = s[bp[i]];
      }
    return t;
  };

const Wrapper = styled('div')``;

const NormalPrice = styled('div')`
  color: ${theme.colors.darkred};
  font-weight: ${theme.fontWeights.bold};
  font-size: 25px;
  line-height: 25px;
  letter-spacing: -0.02em;
  white-space: nowrap;

  &.push-style {
    font-size: 50px;
    line-height: 50px;
  }
`;

const SalesWrapper = styled('div')`
  display: flex;

  .red-tilt-wrapper {
    .content {
      .percentage {
        white-space: nowrap;
      }
    }
  }
`;
const SalesPercentage = styled('div')`
  color: ${theme.colors.white};
  font-weight: ${theme.fontWeights.bold};
  font-size: 16px;
  letter-spacing: -0.02em;
`;
const OldPrice = styled('div')`
  color: ${theme.colors.black};
  font-weight: ${theme.fontWeights.regular};
  line-height: 20px;
  font-size: 16px;
  letter-spacing: -0.02em;
  text-decoration-line: line-through;
  opacity: 0.3;
  white-space: nowrap;

  &.push-style {
    color: ${theme.colors.black};
    font-weight: ${theme.fontWeights.bold};
    font-size: 16px;
    letter-spacing: -0.02em;
    align-self: center;
    text-decoration-line: none;
    opacity: 1;
  }
`;
const NewPrice = styled('div')`
  color: ${theme.colors.darkred};
  font-weight: ${theme.fontWeights.bold};
  font-size: 30px;
  line-height: 30px;
  letter-spacing: -0.02em;
  white-space: nowrap;

  &.push-style {
    font-size: 50px;
    line-height: 50px;
    margin: 0 0 2px 20px;
  }
`;

const PriceComponent = ({ usePushStyle = false, ..._a }) => {
  var _b, _c, _d;
  var { className, style, negative } = _a,
    props = __rest(_a, ['className', 'style', 'negative']);
  const { hasDiscount, formattedPricing } = usePrice(props);
  const { selectedChannel } = useContext(ChannelContext);

  const channelSuffixDictionary = {
    SEK: { newSuffix: ':-', oldSuffix: 'kr' },
    DKK: { newSuffix: '.-', oldSuffix: 'kr.' },
    DKR: { newSuffix: '.-', oldSuffix: 'kr.' }
    // EUR: { newSuffix: '.-', oldSuffix: 'kr' }

    // add more special suffixes for other currensies
  };

  const salesPercentageCalculation = () => {
    let discount =
      (100 * (props?.previousPrice?.incVat - props?.price?.incVat)) /
      props?.previousPrice?.incVat;
    return `-${Math.round(discount)}%`;
  };

  const priceSuffixCorrection = value => {
    const suffix =
      channelSuffixDictionary[selectedChannel?.currency?.name]?.newSuffix;
    const adjustedPriceString = suffix
      ? value
          ?.split(
            channelSuffixDictionary[selectedChannel?.currency?.name]?.oldSuffix
          )
          .join(suffix)
      : value;

    return adjustedPriceString;
  };

  return React.createElement(
    Wrapper,
    Object.assign({ className: className, style: style }, props, {
      'data-flight-price': ''
    }),
    hasDiscount
      ? React.createElement(
          React.Fragment,
          null,
          React.createElement(
            NewPrice,
            {
              as:
                (_b =
                  props === null || props === void 0 ? void 0 : props.as) !==
                  null && _b !== void 0
                  ? _b
                  : 'div',
              className: `${usePushStyle && 'push-style'} new-price`
            },
            priceSuffixCorrection(formattedPricing.price)
          ),

          usePushStyle
            ? React.createElement(
                SalesWrapper,
                null,
                React.createElement(
                  RedTilt,
                  null,
                  React.createElement(
                    SalesPercentage,
                    {
                      as:
                        (_c =
                          props === null || props === void 0
                            ? void 0
                            : props.as) !== null && _c !== void 0
                          ? _c
                          : 'div',
                      className: 'percentage'
                    },
                    salesPercentageCalculation()
                  )
                ),
                React.createElement(
                  OldPrice,
                  {
                    as:
                      (_c =
                        props === null || props === void 0
                          ? void 0
                          : props.as) !== null && _c !== void 0
                        ? _c
                        : 'div',
                    className: `push-style old-price`
                  },
                  priceSuffixCorrection(formattedPricing.previousPrice)
                )
              )
            : React.createElement(
                React.Fragment,
                null,

                React.createElement(
                  OldPrice,
                  {
                    as:
                      (_c =
                        props === null || props === void 0
                          ? void 0
                          : props.as) !== null && _c !== void 0
                        ? _c
                        : 'div',
                    className: 'old-price'
                  },
                  priceSuffixCorrection(formattedPricing.previousPrice)
                )
              )
        )
      : React.createElement(
          NormalPrice,
          {
            as:
              (_d = props === null || props === void 0 ? void 0 : props.as) !==
                null && _d !== void 0
                ? _d
                : 'div',
            className: `${usePushStyle && 'push-style'} price`
          },
          negative && '-',
          priceSuffixCorrection(formattedPricing.price)
        )
  );
};
PriceComponent.Wrapper = Wrapper;
PriceComponent.Normal = NormalPrice;
PriceComponent.Old = OldPrice;
PriceComponent.New = NewPrice;
export default PriceComponent;

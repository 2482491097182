import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const HtmlRowWrapper = styled('section')`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const HtmlWrapper = styled('div')`
  padding: 0 20px;

  ${theme.below.lg} {
    padding: 20px;
  }
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 1rem;
  }
  h1 {
    line-height: 1.2;
  }
  h2 {
    line-height: 1.2;
  }
  p {
    font-size: 14px;
    line-height: 1.5;
    font-weight: ${theme.fontWeights.regular};
    margin-bottom: 1rem;
  }
  ul {
    list-style-type: disc;
    padding-left: 1rem;
    li {
      font-size: 14px;
      line-height: 1.5;
      font-weight: ${theme.fontWeights.regular};
    }
  }
  img {
    max-width: 100%;
  }
`;

export const HtmlRow = ({ children }) => {
  return <HtmlRowWrapper>{children}</HtmlRowWrapper>;
};

export const HtmlComponent = ({ html }) => {
  return (
    <HtmlWrapper
      dangerouslySetInnerHTML={{
        __html: html?.value
      }}
    />
  );
};

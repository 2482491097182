import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import ProductRecommendationsQuery from './ProductRecommendationsQuery.gql';
import MaxWidth from './Layout/MaxWidth';
import { ProductRow, ProductRowItem } from './ContentComponents/ProductRow';
import { Heading2 } from './ui/text/Heading2';
import { Heading3 } from './ui/text/Heading3';
import { Title } from './ContentComponents/Title';
import { Spacer } from './ContentComponents/Spacer';
import { styled } from 'linaria/react';

export const RecommendationsWrapper = styled('div')`
  .title-wrapper {
    margin: 0 16px;
  }
`;

function ProductRecommendations({ title, cartPage, height, items = [] }) {
  const articleNumbers = items.map(item => item?.productNumber);
  const productsResult = useQuery(ProductRecommendationsQuery, {
    variables: {
      articleNumbers
    },
    skip: articleNumbers.length === 0,
    errorPolicy: 'ignore'
  });

  const products =
    productsResult?.data?.products?.map(product => ({
      ...product,
      addwishData: items?.find(
        item => item?.productNumber === product?.articleNumber
      )
    })) || [];

  if (productsResult.loading) {
    return (
      <MaxWidth>
        {cartPage ? <Heading3 text={title} /> : <Heading2 text={title} />}
        <div>Loading</div>
      </MaxWidth>
    );
  } else if (products.length) {
    return (
      <RecommendationsWrapper className="recommendations">
        {cartPage ? (
          <Heading3 text={title} />
        ) : (
          <>
            <div className="title-wrapper">
              <Title text={title} />
            </div>
            <Spacer height={height ?? { value: 'SMALL' }} />
          </>
        )}
        <ProductRow cartPage={cartPage && 2.5}>
          {products.map(product => {
            return <ProductRowItem product={product} />;
          })}
        </ProductRow>
      </RecommendationsWrapper>
    );
  } else {
    return null;
  }
}

export default ProductRecommendations;

import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { useIntl } from '@jetshop/intl';
import ChannelSelector from '@jetshop/ui/ChannelSelector/ChannelSelector';
import { theme } from '../../../Theme';
import { ReactComponent as Close } from '../../../../svg/Close.svg';
import ModalContext from '@jetshop/ui/Modal/ModalContext';
import { Heading3 } from '../../../ui/text/Heading3';
import { Heading4 } from '../../../ui/text/Heading4';
import { ReactComponent as Global } from '../../../../svg/Global.svg';

const GridChannelSelectorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;

  svg {
    color: ${theme.colors.darkred};
  }

  p {
    margin: auto;
    margin-bottom: 20px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
`;

const ChannelsWrapper = styled('div')`
  width: 100%;
  padding-top: 10px;
`;

const ChannelInner = styled('div')`
  transition: 200ms;
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;

  h4 {
    color: ${theme.colors.black};
    opacity: 0.4;
    margin: 0;
  }

  ${theme.below.lg} {
    height: 35px;
  }

  svg {
    height: auto;
    width: 30px;
    margin-right: 16px;
    ${theme.below.lg} {
      width: 20px;
    }
  }

  img,
  svg {
    opacity: 0.75;
  }

  :hover {
    background: #f7f7f7;
    img,
    svg {
      opacity: 1;
    }
  }

  &.active {
    h4 {
      opacity: 1;
    }
    img {
      opacity: 1;
    }
  }
`;

const CountryFlag = styled('img')`
  height: auto;
  width: 30px;
  margin-right: 15px;
  ${theme.below.lg} {
    width: 20px;
  }
`;

export const GridChannelSelector = ({
  channels,
  selectedChannel,
  updateChannel
}) => {
  const { hideTarget } = useContext(ModalContext);
  const t = useIntl();
  const headingText = t('Select your country');

  let activeChannels;
  const activeChannelsB2C = [1, 2, 3, 4];
  const activeChannelsB2B = [6, 7, 8];

  // IS B2C CHANNEL
  if (activeChannelsB2C.includes(selectedChannel?.id)) {
    activeChannels = activeChannelsB2C;
  }
  // IS B2B CHANNEL
  if (activeChannelsB2B.includes(selectedChannel?.id)) {
    activeChannels = activeChannelsB2B;
  }

  return (
    <ChannelSelector
      channels={channels}
      initialChannel={selectedChannel}
      render={({ selectedChannel, onSelect }) => (
        <GridChannelSelectorContainer>
          <div className="header">
            <span onClick={() => hideTarget()}>
              <Close />
            </span>
            <Heading3 text={headingText} />
          </div>
          <ChannelsWrapper>
            {activeChannels &&
              channels.map(channel => {
                return (
                  <React.Fragment key={channel.id}>
                    {activeChannels.includes(channel.id) ? (
                      <ChannelInner
                        key={channel.id}
                        className={
                          channel.id === selectedChannel.id ? 'active' : null
                        }
                        onClick={() => {
                          const chosenChannel = onSelect(
                            channel.id,
                            channel.defaultLanguage.culture,
                            channel.defaultCurrency.id
                          );
                          updateChannel(chosenChannel);
                        }}
                      >
                        {channel.id !== 4 && channel.id !== 7 ? (
                          <CountryFlag
                            src={`https://countryflags.jetshop.io/${channel.countries[0].code}/flat/32.png`}
                            alt={t('Country Flag')}
                          />
                        ) : (
                          <Global />
                        )}
                        <Heading4 text={channel.displayName} />
                      </ChannelInner>
                    ) : null}
                  </React.Fragment>
                );
              })}
          </ChannelsWrapper>
        </GridChannelSelectorContainer>
      )}
    />
  );
};

import t from '@jetshop/intl';
import { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Caret } from '../../../../svg/Arrow-forward.svg';
import { ReactComponent as Close } from '../../../../svg/Close.svg';
import { ReactComponent as Support } from '../../../../svg/Support.svg';
import { ReactComponent as Cart } from '../../../../svg/Cart-menu.svg';
import { ReactComponent as Account } from '../../../../svg/Account.svg';
import { theme } from '../../../Theme';
import { MenuContentCategory } from './MenuContentCategory';
import { useIntl } from '@jetshop/intl';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';

const Scroll = styled('div')`
  flex: 1 1 auto;
`;

const NewDrawer = styled('nav')`
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: ${props => props.topPosition}px;
  height: calc(100vh - ${props => props.topPosition}px);
  z-index: 4;
  max-width: 100%;
  ${theme.below.lg} {
    max-width: 90%;
  }

  width: ${props => props.size}px;
  transform: translateX(${props => (props.isOpen ? 0 : props.size)}px);
  transition: transform 0.2s ease-in-out;
  right: 0;

  &.left {
    left: 0;
    right: auto;
    transform: translateX(${props => (props.isOpen ? 0 : -props.size)}px);
  }
`;
const MenuWrapper = styled('div')`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  opacity: ${props => (props.active ? 1 : 0)};
  overflow-y: ${props => (props.active ? 'auto' : 'hidden')};
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  transform: translateX(${props => (props.active ? 0 : -100)}%);
  transition: ${props => (props.active ? 0.4 : 0.2)}s ease-in-out;

  .categories {
    padding: 0 20px 5px 20px;
  }

  svg {
    color: ${theme.colors.darkred};
  }

  .custom {
    border-bottom: 1px solid ${theme.colors.border};
    span {
      opacity: 0.4;
    }
    svg {
      width: auto;
    }
  }

  &.submenu {
    /* .categories {
      padding: 0 20px 5px 20px;
    } */
    a,
    span {
      font-size: 16px;
    }
    a,
    button {
      padding: 14px 0;
      height: auto;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
    }
  }

  button,
  a {
    svg,
    img {
      width: 1rem;
      object-fit: contain;
    }
    span {
      font-size: 16px;
      line-height: 20px;
      svg,
      img {
        width: 1.5rem;
        max-height: 1rem;
        margin-right: 0.5rem;
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 14px 0;
    text-align: left;
    border-bottom: 1px solid ${theme.colors.border};
    color: ${theme.colors.black};
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    width: 100%;
    span {
      display: flex;
      align-items: center;
    }
    &.red {
      color: ${theme.colors.red};
    }

    &:focus {
      outline: none;
    }
    &:active {
      background: #f9f9f9;
    }
  }
  .backbutton {
    margin-left: -10px;
    border: 0;
    svg {
      transform: rotate(-180deg);
      margin-top: 1px;
      margin-right: 2px;
    }
  }
`;

const MenuHeader = styled('div')`
  display: flex;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid ${theme.colors.border};
  flex-direction: row-reverse;
  padding: 0 20px;

  a {
    border: none;
    svg {
      width: auto;
    }
  }
`;

const MenuLevel = ({
  categories,
  active,
  goBack = () => {},
  parent = null,
  subData,
  ...rest
}) => {
  const [activeId, setActiveId] = useState(null);
  const backHandler = () => {
    setActiveId(null);
  };
  const { loggedIn } = useAuth();
  const t = useIntl();
  const loginString = t('Login');
  const myPagesString = t('My Pages');
  const customerSupportString = t('Customer support');
  const cartString = t('Cart');

  const menuContentId = 174;

  useEffect(() => {
    if (!rest.isOpen && activeId) {
      //To avoid flicker on close
      setTimeout(() => {
        setActiveId(null);
      }, 300);
    }
  }, [rest.isOpen, activeId]);

  return (
    <>
      {active && (
        <>
          <MenuWrapper
            active={active}
            className={parent ? 'submenu' : 'toplevel'}
          >
            <MenuHeader>
              <div>
                <span onClick={rest.close}>
                  <Close />
                </span>
              </div>
              {parent && (
                <button onClick={goBack} className="backbutton">
                  <span className="small">
                    <Caret />
                    {parent.parent ? (
                      <>
                        {t('Back to ')}
                        {parent.parent.name}
                      </>
                    ) : (
                      t('Back')
                    )}
                  </span>
                </button>
              )}
            </MenuHeader>
            <div className="categories">
              {categories.map(cat =>
                cat.hasSubcategories && cat?.subcategories ? (
                  <button
                    key={`btn-${cat.id}`}
                    onClick={() => setActiveId(cat.id)}
                  >
                    <span className={cat?.parent?.id ? 'caps' : ''}>
                      {cat.name}
                    </span>
                    <Caret />
                  </button>
                ) : (
                  <React.Fragment key={cat.id}>
                    {cat?.name?.toLowerCase() !== 'menu content' && (
                      <Link
                        onClick={rest.close}
                        to={cat?.primaryRoute?.path}
                        key={`link-${cat.id}`}
                      >
                        <span className={cx(!parent ? 'caps' : '')}>
                          {cat.name}
                        </span>
                      </Link>
                    )}
                  </React.Fragment>
                )
              )}
              <div>
                <Link onClick={rest.close} to="/login" className="custom">
                  <span>{loggedIn ? myPagesString : loginString}</span>
                  <Account />
                </Link>

                <DrawerTrigger preventOverflow={true} id="cart-drawer">
                  {drawer => (
                    <Link
                      onClick={() => {
                        rest.close();
                        drawer.showTarget();
                      }}
                      to="/"
                      className="custom"
                    >
                      <span>{cartString}</span>
                      <Cart />
                    </Link>
                  )}
                </DrawerTrigger>

                <Link
                  onClick={rest.close}
                  to="/customersupport"
                  className="custom"
                >
                  <span>{customerSupportString}</span>
                  <Support />
                </Link>
              </div>
              {menuContentId && (
                <MenuContentCategory
                  menuContentId={menuContentId}
                  closeNav={rest.close}
                />
              )}
            </div>
          </MenuWrapper>

          {categories?.map(cat =>
            cat.hasSubcategories && cat?.subcategories ? (
              <MenuLevel
                key={`cat-${cat.id}`}
                {...rest}
                parent={{ ...cat, parent: parent }}
                goBack={backHandler}
                categories={cat.subcategories}
                active={cat.id === activeId}
              />
            ) : null
          )}
        </>
      )}
    </>
  );
};

export const PositionedDrawer = ({ isOpen, size = 332, children, left }) => {
  return (
    <NewDrawer
      topPosition={0}
      isOpen={isOpen}
      size={size}
      className={left ? 'left' : ''}
    >
      {children}
    </NewDrawer>
  );
};

export default function FlyoutMenu({ categoryData, size = 332, left = false }) {
  if (!(categoryData && categoryData.categories)) return null;
  return (
    <>
      <DrawerTarget id="menu-drawer">
        {drawer => (
          <PositionedDrawer isOpen={drawer.isOpen} size={size} left={left}>
            <Scroll>
              <MenuLevel
                isOpen={drawer.isOpen}
                close={drawer.hideTarget}
                categories={categoryData.categories}
                active={true}
              />
            </Scroll>
          </PositionedDrawer>
        )}
      </DrawerTarget>
    </>
  );
}

import { theme } from '../../Theme';
import { css } from 'linaria';

export const fontWeightsStyles = css`
  &.light {
    font-weight: ${theme.fontWeights.light};
  }
  &.regular {
    font-weight: ${theme.fontWeights.regular};
  }
  &.medium {
    font-weight: ${theme.fontWeights.medium};
  }
  &.semibold {
    font-weight: ${theme.fontWeights.semibold};
  }
  &.bold {
    font-weight: ${theme.fontWeights.bold};
  }
`;

import { styled } from 'linaria/react';
import React from 'react';
import { Heading2 } from '../ui/text/Heading2';
import { theme } from '../Theme';

const SeoInnerWrapper = styled('div')`
  text-align: left;
  h1,
  h2 {
    margin-bottom: 1rem;
  }
  h3,
  h4 {
    margin-bottom: 0.45rem;
  }
  h1 {
    line-height: 1.2;
  }
  h2 {
    line-height: 1.2;
  }
  p {
    font-size: 14px;
    line-height: 1.5;
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors.black};
    margin-bottom: 1rem;
  }
  ul,
  ol {
    list-style-type: disc;
    padding-left: 1rem;
    li {
      font-size: 14px;
      line-height: 1.5;
      font-weight: ${theme.fontWeights.regular};
      color: ${theme.colors.black};
    }
  }
`;

export const SeoItem = ({ title, html }) => {
  return (
    <SeoInnerWrapper>
      <Heading2 text={title?.value} />
      <div
        dangerouslySetInnerHTML={{
          __html: html?.value
        }}
      />
    </SeoInnerWrapper>
  );
};

export const Seo = ({ children }) => {
  return <div>{children}</div>;
};

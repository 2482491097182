import { styled } from 'linaria/react';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../svg/Logo.svg';
import { ReactComponent as Account } from '../../../svg/Account.svg';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import IconRow from './IconRow';
import { theme } from '../../Theme';
import CampaignBarQuery from './CampaignBarQuery.gql';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { allContentComponents } from '../../ContentComponents/ContentComponents';
import { useIntl } from '@jetshop/intl';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { B2bBanner } from './B2bBanner';
import useChannelCheck from '../../hooks/useChannelCheck';
const DesktopHeaderWrapper = styled('div')`
  background: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 3;
`;

const HeaderColumn = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
  &.center {
    flex: none;
  }
  &.right {
    justify-content: flex-end;
  }
  &.left {
    button {
      background: none;
    }
  }
`;
const LogoLink = styled(Link)`
  padding: 10px 0;
  svg {
    height: 40px;
  }
`;

const DesktopHeaderMenuWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;

const MenuLinks = styled('div')`
  justify-content: flex-end;
  padding: 0 40px 0 0;
  width: auto;
  max-width: 30%;
  display: flex;
  a {
    color: #1e1e1e66;
    text-decoration: none;
    font-size: 16px;
    line-height: 18px;
    margin-left: 16px;
    margin-top: 5px;
    white-space: nowrap;
  }
  svg {
    margin-top: -13px;
    margin-left: 6px;
    width: 21px;
    height: 23px;
  }
`;

const DesktopTopWrapper = styled('div')`
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  height: 76px;
  position: sticky;
  top: 0;
  z-index: 3;
  border-bottom: 1px solid ${theme.colors.border};
`;

export const HeaderCampaignBar = React.memo(() => {
  const { data } = useQuery(CampaignBarQuery, {
    variables: {
      id: 173
    }
  });
  const items = data?.category?.data?.items;

  return (
    <>
      {items && (
        <ContentRenderer items={items} components={allContentComponents} />
      )}
    </>
  );
});

export default function DesktopHeader({
  categories,
  searchOpen,
  setSearchOpen
}) {
  const { loggedIn } = useAuth();
  const { routes } = useShopConfig();
  const t = useIntl();
  const customerSupportString = t('Customer support');
  const loginString = t('Login');
  const myPagesString = t('My Pages');

  const { isB2B } = useChannelCheck();

  return (
    <>
      <DesktopHeaderWrapper id="site-header">
        {isB2B && <B2bBanner />}

        <DesktopTopWrapper>
          <HeaderColumn className="left">
            <LogoLink to="/" className="logo-link">
              <Logo />
            </LogoLink>
          </HeaderColumn>
          <HeaderColumn className="right">
            <IconRow searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
          </HeaderColumn>
        </DesktopTopWrapper>
        <DesktopHeaderMenuWrapper>
          <CategoryMenu data={categories.data} />
          <MenuLinks>
            {/* TODO - Dynamic link to page ?  */}
            <Link to="/customersupport">{customerSupportString}</Link>
            <Link to={routes.myPages.path}>
              {loggedIn ? myPagesString : loginString}
              <Account className={'account-icon'} />
            </Link>
          </MenuLinks>
        </DesktopHeaderMenuWrapper>
        <HeaderCampaignBar />
      </DesktopHeaderWrapper>
    </>
  );
}

import FlyoutMenu from './CategoryMenu/FlyoutMenu';
import { Above } from '@jetshop/ui/Breakpoints';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { Notifications } from '../Notifications';
import homeCategoriesQuery from './Categories/HomeCategoriesQuery.gql';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import CartFlyout from '../../Cart/CartFlyout';
import ChannelFlyout from './ChannelSelector/ChannelFlyout';
import { QuickBuyFlyout } from '../QuickBuy/QuickBuyFlyout';
import { QuickBuyTrigger } from '../QuickBuy/QuickBuyTrigger';

export default function Header() {
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });

  const [searchOpen, setSearchOpen] = useState(true);
  return (
    <>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <>
              <DesktopHeader
                categories={result}
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
              />
            </>
          ) : (
            <>
              <MobileHeader
                searchOpen={searchOpen}
                setSearchOpen={setSearchOpen}
              />
            </>
          )
        }
      </Above>
      <CartFlyout />
      <FlyoutMenu left={true} categoryData={result.data} />
      <ChannelFlyout left={false} />
      <QuickBuyFlyout />
      <QuickBuyTrigger />
      <Notifications />
    </>
  );
}

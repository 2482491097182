import React from 'react';
import { theme } from '../../Theme';
import { cx, css } from 'linaria';
import { fontWeightsStyles } from '../sharedStyles/FontWeightStyles';

export const paragraphStyles = css`
  font-size: ${theme.fontSizes.paragraph};
  line-height: ${theme.lineHeights.paragraph};
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colors.black};
`;

/**
 * The Paragraph function is a component that renders text.
 *
 *
 * @param {String} weight  Set the weight of the paragraph (select classnames from fontWeightsStyles)
 * @param {String} text Text to be presented in component
 *
 * @return A element with a class of paragraph and the font-weight styles presenting text
 *
 * @docauthor Anders Zetterström @ R3
 */
export const Paragraph = ({ weight, text }) => {
  return (
    <p
      className={cx('paragraph', paragraphStyles, fontWeightsStyles, weight)}
      dangerouslySetInnerHTML={{
        __html: text
      }}
    ></p>
  );
};

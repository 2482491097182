import React from 'react';
import { styled } from 'linaria/react';
import { ContactUs } from './ContactUs';
import { FooterLinks } from './FooterLinks';
import { CompanyInfo } from './CompanyInfo';

const FooterWrapper = styled('section')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Footer = () => {
  return (
    <FooterWrapper>
      <ContactUs />
      <FooterLinks />
      <CompanyInfo />
    </FooterWrapper>
  );
};

import { useListFilter } from '@jetshop/core/hooks/Filters/useListFilter';
import { useRangeFilter } from '@jetshop/core/hooks/Filters/useRangeFilter';
import { useBooleanFilter } from '@jetshop/core/hooks/Filters/useBooleanFilter';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';
import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';

export const FilterGroup = styled('div')``;
const Label = styled('label')`
  padding-right: 0.25rem;
`;
const Value = styled('span')`
  padding-right: 11px;
`;
export const AppliedFilter = styled('button')`
  color: ${theme.colors.black};
  /* border: 1px solid ${theme.colors.alphablack}; */
  padding: 0 20px;
  display: flex;
  background: ${theme.colors.alphablack};
  height: 33px;
  align-items: center;
  border-radius: 20px;
  width: fit-content;
  font-weight: ${theme.fontWeights.regular};
  font-size: ${theme.fontSizes.button};
  line-height: ${theme.fontSizes.button};

  svg {
    width: 12px;
    height: 12px;
    path {
      fill: ${theme.colors.black};
    }
  }

  :hover,
  :active {
    border: 1px solid ${theme.colors.border};
  }
`;

export const FilterWrapper = styled('aside')`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
  margin-left: 20px;
  div {
    margin-right: 10px;
  }
  ${theme.below.lg} {
    justify-content: flex-start;
    width: 100%;
    margin: 10px 0;
  }
`;

export const ClearButton = styled('button')`
  background: ${theme.colors.transparent};
  border: none;
  margin-left: 0.5rem;
  width: 160px;
  height: 36px;
  color: ${theme.colors.black};
  margin: 0.5rem;
  text-decoration: none;
  font-weight: ${theme.fontWeights.regular};
  font-size: ${theme.fontSizes.button};
  line-height: ${theme.fontSizes.button};
  :hover,
  :focus {
    opacity: 0.8;
    outline: none;
  }
`;

export function ActiveFilters({ filters }) {
  if (!filters) return null;

  const activeListFilters = filters.filter(listFilter => {
    return listFilter.hasActiveItems;
  });

  const activeMultiListFilters = filters.filter(
    multiListFilter =>
      multiListFilter.__typename === 'MultiListFilter' &&
      multiListFilter.hasActiveItems
  );

  const activeNumericRangeFilter = filters.filter(
    numericRangeFilter =>
      numericRangeFilter.__typename === 'NumericRangeFilter' &&
      numericRangeFilter.isActive
  );

  const activeBooleanFilter = filters.filter(
    booleanFilter =>
      booleanFilter.__typename === 'BooleanFilter' && booleanFilter.value
  );

  if (
    activeListFilters.length <= 0 &&
    activeMultiListFilters.length <= 0 &&
    activeNumericRangeFilter.length <= 0 &&
    activeBooleanFilter.length <= 0
  ) {
    return null;
  }

  return (
    <FilterWrapper>
      <>
        {activeListFilters.length > 0 &&
          activeListFilters.map(listFilter => {
            return (
              <FilterGroup key={listFilter.id}>
                {listFilter.items
                  .filter(item => item.isActive)
                  .map(listFilterItem => (
                    <ActiveListFilterItem
                      item={listFilterItem}
                      listFilter={listFilter}
                      key={listFilterItem.value}
                    />
                  ))}
              </FilterGroup>
            );
          })}

        {activeNumericRangeFilter.length > 0 &&
          activeNumericRangeFilter.map(numericRangeFilter => {
            return (
              <FilterGroup key={numericRangeFilter.id}>
                <ActiveNumericRangeFilterItem item={numericRangeFilter} />
              </FilterGroup>
            );
          })}

        {activeBooleanFilter.length > 0 &&
          activeBooleanFilter.map(booleanFilter => {
            return (
              <FilterGroup key={booleanFilter.id}>
                <ActiveBooleanFilterItem item={booleanFilter} />
              </FilterGroup>
            );
          })}
        {/* 
        <ClearButton type="button" onClick={clearAllFilters}>
          {t('Clear all filters')}
        </ClearButton> */}
      </>
    </FilterWrapper>
  );
}

export const ActiveListFilterItem = ({ item, listFilter }) => {
  const { apply } = useListFilter({ filter: listFilter });
  return (
    <AppliedFilter
      type="button"
      key={item.value}
      onClick={() => {
        apply({ value: item.value });
      }}
    >
      <Label>{listFilter.name} </Label>
      <Value>{item.text}</Value>
      <Cross />
    </AppliedFilter>
  );
};

export const ActiveNumericRangeFilterItem = ({ item }) => {
  const { clear } = useRangeFilter({ filter: item });
  return (
    <AppliedFilter
      type="button"
      key={item.id}
      onClick={() => {
        clear();
      }}
    >
      <Label>{item.name} </Label>
      <Value>{item?.value?.[0] + ' - ' + item?.value?.[1]}</Value>
      <Cross />
    </AppliedFilter>
  );
};

export const ActiveBooleanFilterItem = ({ item }) => {
  const { apply } = useBooleanFilter({ filter: item });
  return (
    <AppliedFilter
      type="button"
      key={item.id}
      onClick={() => {
        apply({ value: !item.value });
      }}
    >
      <Label>{item.name} </Label>
      <Cross />
    </AppliedFilter>
  );
};

import React from 'react';
import { useAddwish } from '@jetshop/flight-addwish';
import ProductRecommendations from '../ProductRecommendations';

export const HelloRetailContentComponent = ({ title, boxKey, height }) => {
  const boxKeyValue = boxKey?.value;
  const { addWishResponseBoxItems } = useAddwish({
    boxes: {
      [boxKeyValue]: {}
    },
    pageLoaded: true
  });

  return (
    <>
      <ProductRecommendations
        title={title}
        items={addWishResponseBoxItems[boxKeyValue]?.items}
        height={height}
      />
    </>
  );
};

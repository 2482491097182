import React from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import { theme } from '../Theme';

const StyledButtonWrapper = styled('div')`
  height: 41px;
  font-size: ${theme.fontSizes.button};
  letter-spacing: 3%;
  width: fit-content;
  padding: 0 2rem;
  cursor: pointer;
  border-radius: 4px;

  &.RED {
    background: ${theme.colors.red};

    a,
    div {
      color: ${theme.colors.white};
    }
  }

  &.BLACK_OUTLINED {
    background: ${theme.colors.transparent};
    border: 1px solid ${theme.colors.black};

    a,
    div {
      color: ${theme.colors.black};
    }
  }

  &.WHITE_OUTLINED {
    background: ${theme.colors.transparent};
    border: 1px solid ${theme.colors.white};

    a,
    div {
      color: ${theme.colors.white};
    }
  }

  &.GREY {
    background: ${theme.colors.grey};

    a,
    div {
      color: ${theme.colors.black};
    }
  }

  :hover,
  :active {
    opacity: 0.8;
  }

  &.disabled {
    cursor: not-allowed;
    background: ${theme.colors.lightgrey};
    a,
    div {
      color: ${theme.colors.black};
    }
  }
`;

const CenterButtonContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * The StyledButton function is a React component that renders a styled button.
 * @param {String} testid A unique identifier for this component, used to aid in accessibility testing. Defaults to an empty string.
 * @param {String} linkHref Determine if the button should be a linked or not
 * @param {String} className An optional class name that will be added to the rendered element's `class` attribute value when present and not empty or undefined
 * @param {function} clickCallback Pass a callback function to the button
 * @param children Pass in the text or elements that will be displayed in the button
 *
 * @return A styled button wrapper with a click callback, and the children
 *
 * @docauthor Anders Zetterström @ R3
 */
export const StyledButton = ({
  testid,
  linkHref,
  className,
  clickCallback,
  disabled,
  children
}) => {
  return (
    <StyledButtonWrapper
      className={cx(className, 'styled-button-wrapper', disabled && 'disabled')}
      onClick={clickCallback}
      data-testid={testid || ''}
    >
      <LinkEvaluator link={linkHref}>
        <CenterButtonContent>{children}</CenterButtonContent>
      </LinkEvaluator>
    </StyledButtonWrapper>
  );
};

export const ContentEditorButton = ({ buttonText, buttonTheme, link }) => {
  return (
    <StyledButton linkHref={link?.value} className={buttonTheme?.value}>
      {buttonText?.value}
    </StyledButton>
  );
};

import React from 'react';
import { theme } from '../Theme';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import { fontWeightsStyles } from '../ui/sharedStyles/FontWeightStyles';

const FooterLinksColumnWrapper = styled('div')`
  padding: 0 1rem 1rem 1rem;

  .column-title {
    color: ${theme.colors.black};
    font-size: ${theme.fontSizes.largeParagraph};
    margin-bottom: 1rem;
  }

  .column-links {
    padding-left: 20px;
    a {
      color: ${theme.colors.black};
      font-size: ${theme.fontSizes.largeParagraph};
      line-height: 24px;
      opacity: 0.5;
    }
  }
`;

export const FooterLinksColumn = ({ title, children }) => {
  return (
    <FooterLinksColumnWrapper>
      <p className={cx('column-title', 'regular', fontWeightsStyles)}>
        {title?.value}
      </p>
      <div className="column-links">{children}</div>
    </FooterLinksColumnWrapper>
  );
};

export const FooterLinksColumnItem = ({ linkName, link }) => {
  return (
    <div>
      <LinkEvaluator link={link?.value}>
        <p>{linkName?.value}</p>
      </LinkEvaluator>
    </div>
  );
};

import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import Image from '@jetshop/ui/Image';
import { Heading2 } from '../ui/text/Heading2';
import { Paragraph } from '../ui/text/Paragraph';
import { ProductCard } from '../CategoryPage/ProductCard';
import { Above } from '@jetshop/ui/Breakpoints';

const DoubleRowItemWrapper = styled('div')`
  width: 100%;
  margin: 0 auto;
  max-width: ${theme.maxWidth};
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  ${theme.below.lg} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .double-row-top-image {
    grid-area: 1 / 1 / 2 / 12;

    ${theme.below.lg} {
      grid-area: 2 / 1 / 4 / 3;
    }
  }
  .double-row-text {
    grid-area: 1 / 12 / 2 / 17;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: 75%;
    margin-bottom: 20px;

    ${theme.below.xl} {
      width: 90%;
    }
    ${theme.below.lg} {
      grid-area: 1 / 1 / 2 / 4;
      width: 100%;
      padding: 0 10px;
      align-items: flex-start;
    }

    .heading-2 {
      margin-bottom: 1rem;
    }
  }
  .product-card {
    grid-area: 2 / 4 / 3 / 9;
    display: flex;

    ${theme.below.lg} {
      grid-area: 4 / 1 / 6 / 4;
    }
    a {
      width: 100%;
      height: 100%;

      .product-card-detail {
        padding-top: 4px;
        padding-bottom: 4px;
      }
      .stockstatus-wrapper {
        margin-top: 8px;
      }
    }
  }

  .double-row-bottom-image {
    grid-area: 2 / 9 / 3 / 17;
    ${theme.below.lg} {
      grid-area: 3 / 3 / 4 / 4;
    }
  }
`;

export const DoubleRow = ({
  desktopTopImage,
  desktopTopImageRatio,
  mobileTopImage,
  mobileTopImageRatio,
  header,
  text,
  product,
  desktopBottomImage,
  desktopBottomImageRatio,
  mobileBottomImage,
  mobileBottomImageRatio
}) => {
  const imageSizes = [1, 1, 1, 300, 600];

  return (
    <DoubleRowItemWrapper>
      <Above breakpoint="lg">
        {matches => (
          <>
            <Image
              sizes={imageSizes}
              className="double-row-top-image"
              src={matches ? desktopTopImage?.value : mobileTopImage?.value}
              aspect={
                matches
                  ? desktopTopImageRatio?.value
                  : mobileTopImageRatio?.value
              }
              cover
            />
            <div className="double-row-text">
              {header?.value && (
                <Heading2 text={header?.value} fontSize={'30px'} />
              )}
              {text?.value && <Paragraph text={text?.value} />}
            </div>

            <ProductCard product={product?.value} />
            <Image
              sizes={imageSizes}
              className="double-row-bottom-image"
              src={
                matches ? desktopBottomImage?.value : mobileBottomImage?.value
              }
              aspect={
                matches
                  ? desktopBottomImageRatio?.value
                  : mobileBottomImageRatio?.value
              }
              cover
            />
          </>
        )}
      </Above>
    </DoubleRowItemWrapper>
  );
};

import React, { useState, useEffect } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import t, { useIntl } from '@jetshop/intl';
import { Heading2 } from '../ui/text/Heading2';
import { QtyAdjuster } from '../ui/AddToCartQtyButton';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import { StyledPrice } from '../ui/StyledPrice';
import { ReactComponent as ChevronRight } from '../../svg/ChevronRight.svg';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useScrollPosition } from '../hooks/HeaderHeightContext';
import { QuickBuyButton } from '../Layout/QuickBuy/QuickBuyButton';
import { Link } from 'react-router-dom';
import { StyledBreadcrumbs } from '../ui/StyledBreadcrumbs';
import { useLocation } from 'react-router';
import breadcrumbsQuery from '../CategoryPage/Breadcrumbs.gql';
import { useQuery } from '@apollo/react-hooks';
import { StockStatusHolder } from '../ui/StockStatusHolder';

const ExplodedWrapper = styled('div')`
  width: 100%;

  .exploded-image {
    background: ${theme.colors.lightgrey};
    text-align: center;
    .react-transform-wrapper {
      margin: auto;
    }
    .react-transform-component {
      mix-blend-mode: darken;
    }
    img {
      mix-blend-mode: darken;
      max-width: 100%;
    }

    &.sticky {
      position: fixed;
      top: 90px;
      left: 0;
      right: 0;
      z-index: 3;
      * {
        max-height: 70vh;
      }
    }
  }

  .exploded-search {
    background: ${theme.colors.lightgrey};
    text-align: center;
    padding: 20px 20px 50px 20px;
    ${theme.below.lg} {
      padding: 20px;
    }

    input {
      background: white;
      border-radius: 4px;
      border: 1px solid #dadada;
      height: 40px;
      padding: 0 10px;
      width: 100%;
      max-width: 400px;
      ${theme.below.lg} {
        max-width: 100%;
      }
      &:focus {
        outline: none;
        background: #f9f9f9;
      }
    }

    .exploded-search-term {
      margin-top: 20px;
    }
  }

  .inner-wrapper {
    max-width: 800px;
    margin: auto;
    padding: 0px 20px 50px 20px;
    ${theme.below.lg} {
      padding: 0 10px 40px 10px;
    }

    .breadcrumbs-wrapper {
      ${theme.above.lg} {
        display: flex;
        justify-content: start;
        padding: 18px 0 30px 0;
        width: 100%;
      }

      ${theme.below.lg} {
        > div {
          display: flex;
          justify-content: center;
          padding: 16px;
          padding: 10px 0;
        }
      }
    }

    .exploded-titles {
      margin-bottom: 50px;
    }
  }
`;

const ExplodedProductWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  border-top: 1px solid #eee;
  margin-top: 15px;
  padding-top: 15px;

  ${theme.below.lg} {
    margin-top: 10px;
    padding-top: 10px;
  }

  .exploded-product-image {
    width: 18%;
    background: ${theme.colors.lightgrey};
    [data-flight-image-container] {
      mix-blend-mode: darken;
    }
  }
  .exploded-product-info {
    width: 82%;
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    ${theme.below.lg} {
      padding-left: 10px;
    }

    .exploded-product-misc {
      position: relative;
      margin-bottom: 5px;

      .exploded-product-positional {
        background: ${theme.colors.black};
        color: white;
        padding: 1px 3px;
        margin-right: 5px;
        font-size: 13px;
        min-width: 30px;
        text-align: center;
        display: inline-block;
      }
      .exploded-product-articlenumber {
        font-weight: bold;
        font-size: 13px;
      }

      [data-flight-price] {
        position: absolute;
        top: 0;
        right: 0;
        text-align: right;
        * {
          text-align: right;
          margin-left: 5px;
        }
      }
    }

    .exploded-product-titles {
      font-size: 16px;
      ${theme.below.lg} {
        font-size: 12px;
      }
      span {
        display: block;
        line-height: 1.2;
        &.free-text {
          font-size: 12px;
          ${theme.below.lg} {
            font-size: 10px;
          }
        }
      }
    }

    .exploded-product-bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 5px;

      .exploded-product-bottom-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        > div {
          width: auto;
          margin-right: 10px;
        }

        .qty-adjuster {
          input {
            width: 40px;
            height: 36px;
            ${theme.below.lg} {
              width: 35px;
              height: 31px;
            }
          }
          button {
            width: 40px;
            height: 40px;
            svg {
              rect {
                fill: ${theme.colors.black};
              }
            }
            ${theme.below.lg} {
              width: 35px;
              height: 35px;
            }
          }
        }
      }
      .exploded-product-bottom-right {
        flex: none;
        margin-bottom: 6px;

        .price {
          ${theme.below.lg} {
            font-size: 14px;
          }
        }
      }

      /* TEMP */
      .styled-button-wrapper {
        display: none;
      }
      /* TEMP */
    }
  }
`;

const ToggleExploded = styled('button')`
  background: ${theme.colors.lightgrey};
  border: 0;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 5px 0;
  top: 155px;
  ${theme.below.lg} {
    top: 90px;
  }
  span {
    display: block;
    font-size: 14px;
    line-height: 1;
    color: ${theme.colors.black};
    svg {
      transform: rotate(90deg);
      width: 10px;
      height: 10px;
      path {
        stroke: ${theme.colors.black};
      }
    }
  }

  &.reverse {
    position: relative;
    top: auto;
    span {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

export const ExplodedProduct = ({ product, positionalNumber, freeText }) => {
  const prod = product?.value;
  const hasImages = prod?.images?.length > 0;
  const imageAspect = '1:1';
  const imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4];

  const [qty, setQty] = useState(1);
  const articleNumber = prod?.articleNumber;

  if (!prod) return null;

  return (
    <ExplodedProductWrapper>
      <div className="exploded-product-image">
        <StyledLink to={product?.value?.primaryRoute?.path}>
          {hasImages ? (
            <Image
              className="product-card-image"
              sizes={imageSizes}
              aspect={imageAspect}
              alt={prod.images[0]?.alt}
              src={prod.images[0]?.url}
              modifiedDate={prod.images[0]?.modifiedDate}
              critical={true}
            ></Image>
          ) : (
            <Image src={transparentDataImg} />
          )}
        </StyledLink>
      </div>
      <div className="exploded-product-info">
        <div className="exploded-product-misc">
          <StyledPrice price={prod.price} />
          <StyledLink to={product?.value?.primaryRoute?.path}>
            {positionalNumber?.value && (
              <span className="exploded-product-positional">
                {positionalNumber?.value}
              </span>
            )}
            <span className="exploded-product-articlenumber">
              {articleNumber}
            </span>
          </StyledLink>
        </div>
        <div className="exploded-product-titles">
          <span>{prod?.name}</span>
          <span className="free-text">{freeText?.value}</span>
        </div>
        <div className="exploded-product-bottom">
          <div className="exploded-product-bottom-left">
            <QtyAdjuster qty={qty} setQty={setQty} />
            {prod?.stockStatus?.text && <StockStatusHolder product={prod} />}
          </div>
          <div className="exploded-product-bottom-right">
            <QuickBuyButton product={prod} qty={qty} />
          </div>
        </div>
      </div>
    </ExplodedProductWrapper>
  );
};

export const ExplodedView = ({
  explodedImage,
  explodedSmallTitle,
  explodedTitle,
  children
}) => {
  const location = useLocation();
  const { data } = useQuery(breadcrumbsQuery, {
    variables: {
      path: location?.pathname
    },
    errorPolicy: 'all'
  });

  const breadcrumbProps = {
    breadcrumbText: data?.route?.object?.breadcrumbText,
    parents: data?.route?.parents
  };

  const scroll = useScrollPosition();
  const [term, setTerm] = useState('');
  const [expanded, setExpanded] = useState(false);
  const translate = useIntl();
  const placeholderText = translate('Type position no. or engine part no.');

  const [topPos, setTopPos] = useState(0);
  const docCheck = typeof document;
  useEffect(() => {
    let tempPos = 0;
    if (typeof document !== 'undefined') {
      const explodedImageHeight =
        document.getElementById('explodedImage')?.clientHeight ?? 0;

      tempPos += explodedImageHeight;
      if (scroll < explodedImageHeight) {
        tempPos -= scroll;
      } else if (scroll > explodedImageHeight) {
        tempPos -= explodedImageHeight;
      }
      setTopPos(tempPos);
    }
  }, [docCheck, scroll]);

  const handleSearch = e => {
    setTerm(e.target.value);
  };

  const filteredChildren = children.filter(
    child =>
      child.props?.product?.value?.name?.toLowerCase().indexOf(term) > -1 ||
      child.props?.positionalNumber?.value?.startsWith(term) ||
      child.props?.product?.value?.articleNumber?.startsWith(term)
  );

  return (
    <ExplodedWrapper>
      <div id="explodedImage" className="exploded-image">
        <div className="exploded-image-inner">
          <TransformWrapper wheel={{ disabled: true }}>
            <TransformComponent>
              <img
                src={explodedImage?.value?.value}
                alt={explodedTitle || 'Radne Motor'}
              />
            </TransformComponent>
          </TransformWrapper>
        </div>
        <div className="exploded-search">
          <input
            type="text"
            placeholder={placeholderText}
            onKeyUp={handleSearch}
          />
          {term && (
            <div className="exploded-search-term">
              {t('You searched for:')} <strong>{term}</strong>
            </div>
          )}
        </div>
        {topPos === 0 && (
          <ToggleExploded onClick={() => setExpanded(!expanded)}>
            <span>{t('Show exploded view')}</span>
            <span>
              <ChevronRight />
            </span>
          </ToggleExploded>
        )}
      </div>
      {expanded && topPos === 0 && (
        <div className="exploded-image sticky">
          <div className="exploded-image-inner">
            <TransformWrapper>
              <TransformComponent>
                <img
                  src={explodedImage?.value?.value}
                  alt={explodedTitle || 'Radne Motor'}
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
          <div className="exploded-search">
            <input
              type="text"
              placeholder={placeholderText}
              onKeyUp={handleSearch}
            />
          </div>
          {topPos === 0 && (
            <ToggleExploded
              className="reverse"
              onClick={() => setExpanded(!expanded)}
            >
              <span>{t('Hide exploded view')}</span>
              <span>
                <ChevronRight />
              </span>
            </ToggleExploded>
          )}
        </div>
      )}
      <div className="inner-wrapper">
        <div className="breadcrumbs-wrapper">
          <StyledBreadcrumbs {...breadcrumbProps} />
        </div>
        <div className="exploded-titles">
          <h3>{explodedSmallTitle?.value}</h3>
          <Heading2 text={explodedTitle?.value} />
        </div>
        <div className="product-list">
          {term.length > 0 ? filteredChildren : children}
        </div>
      </div>
    </ExplodedWrapper>
  );
};

import React, { useState, useEffect, useCallback } from 'react';

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const pageOffset = typeof window !== 'undefined' ? window.pageYOffset : 0;
  const immediate = pageOffset === 0;
  const wait = 200;
  const debounce = useCallback(
    func => {
      let timeout;
      return function() {
        const context = this;
        const args = arguments;
        const later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    [immediate]
  );

  useEffect(() => {
    const updatePosition = debounce(() => {
      setScrollPosition(window.pageYOffset);
    });
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, [debounce, immediate]);

  return scrollPosition;
};

const HeaderHeightContext = React.createContext();

function HeaderHeightProvider({ children }) {
  const scroll = useScrollPosition();
  //const [topPos, setTopPos] = useState(0);
  const docCheck = typeof document;
  useEffect(() => {
    //let tempPos = 0;
    if (typeof document !== 'undefined') {
      const topBarHeight =
        document.getElementById('campaign-bar')?.clientHeight ?? 0;
      //tempPos += document.getElementById('site-header')?.clientHeight ?? 0;
      //tempPos += topBarHeight;
      if (scroll < topBarHeight) {
        //tempPos -= scroll;
      } else if (scroll > topBarHeight) {
        //tempPos -= topBarHeight;
      }
      //setTopPos(tempPos);
    }
  }, [docCheck, scroll]);

  //changed value to 0
  const value = { headerHeight: 0 };

  return (
    <HeaderHeightContext.Provider value={value}>
      {children}
    </HeaderHeightContext.Provider>
  );
}

export { HeaderHeightProvider, HeaderHeightContext };

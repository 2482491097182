import React from 'react';
import Image from '@jetshop/ui/Image';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import { Above } from '@jetshop/ui/Breakpoints';
import { BoxContent } from './BoxContent';
import { filterChildrenByKey } from '../../utils/HelpFunctions';
import { theme } from '../Theme';

export const HeroMaxWidth = styled('div')`
  position: relative;
  padding: 0;
  width: 100%;
  max-width: ${theme.maxWidth};
  margin: auto;

  &.fullwidth {
    max-width: 100%;
  }
`;

export const ImageHero = ({
  fullWidth,
  desktopImage,
  desktopImageRatio,
  mobileImage,
  mobileImageRatio,
  tintLayer,
  link,
  textColor,
  header,
  preHeader,
  textAlignment,
  horizontalPosition,
  verticalPosition,
  isH1,
  children
}) => {
  //TODO - handle padding for BoxContent to fit Hero
  const buttonChildren = filterChildrenByKey(children, 'button', true);

  const imageSizes = [1, 1, 1, 1, 1];
  return (
    <Above breakpoint="lg">
      {matches => (
        <HeroMaxWidth className={cx(fullWidth?.value && 'fullwidth')}>
          <LinkEvaluator link={link}>
            <Image
              // TODO - add sizes ??
              src={matches ? desktopImage?.value : mobileImage?.value}
              aspect={
                matches ? desktopImageRatio?.value : mobileImageRatio?.value
              }
              cover
              critical
              sizes={imageSizes}
            />
            <BoxContent
              verticalPosition={verticalPosition}
              horizontalPosition={horizontalPosition}
              tintLayer={tintLayer}
              color={textColor}
              preHeader={preHeader}
              header={header}
              textAlignment={textAlignment}
              buttonChildren={buttonChildren}
              forType={'hero'}
              isH1={isH1}
            />
          </LinkEvaluator>
        </HeroMaxWidth>
      )}
    </Above>
  );
};

import React from 'react';
import { ActiveListFilterItem } from '../CategoryPage/Filters/ActiveFilters';
import { Checkbox } from './Checkbox';
import { RedTilt } from './RedTilt';
import { SliderButton } from './slider/SliderButton';
import { StyledButton } from './StyledButton';
// import { PushPrice } from './StyledPrice';
import { Heading1 } from './text/Heading1';
import { Heading2 } from './text/Heading2';
import { Heading3 } from './text/Heading3';
import { Heading4 } from './text/Heading4';
import { HeadingJumbo } from './text/HeadingJumbo';
import { LargeParagraph } from './text/LargeParagraph';
import { Paragraph } from './text/Paragraph';

/**
 * The UICollection function is a React component that presents project specifik ui components
 *
 * @return All its children
 *
 * @docauthor Anders Zetterström @ R3
 */
export const UICollection = () => {
  // TEMP CODE - Remove
  const callMe = () => {
    console.log('TAPPED!!!!');
  };

  const checkboxCallback = value => {
    console.log('checked: ', value);
  };
  //*********** */
  return (
    <div
      style={{
        margin: 'auto',
        maxWidth: '500px',
        padding: '4rem',
        background: '#f7f7f7'
      }}
    >
      <StyledButton className={'RED'}>Button</StyledButton>
      <StyledButton className={'BLACK_OUTLINED'}>Button</StyledButton>
      <StyledButton className={'WHITE_OUTLINED'}>Button</StyledButton>
      <StyledButton className={'GREY'}>Button</StyledButton>
      <StyledButton className={'RED disabled'}>Button</StyledButton>
      <RedTilt>
        <p>48% rabatt</p>
      </RedTilt>
      <HeadingJumbo text={'Heading Jumbo'} />
      <Heading1 text={'Heading :h1'} />
      <Heading2 text={'Heading :h2'} />
      <Heading3 text={'Heading :h3'} />
      <Heading4 text={'Heading :h4'} />
      <Paragraph weight={'bold'} text={'Sweatshirt med dragkedja<'} />
      <Paragraph
        text={
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>Sed hendrerit ultrices pellentesque. Vivamus bibendum <br/>felis ut ligula tempor rhoncus. Donec bibendum tristique <br/>massa sit amet venenatis.'
        }
      />

      <LargeParagraph text={'Lorem ipsum dolor sit amet.'} />
      {/* <PushPrice {...price} /> */}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <SliderButton direction={'left'} callback={callMe} />
        <SliderButton direction={'right'} />
      </div>
      <Checkbox text={'Option 1'} changeCallback={checkboxCallback} />
      <Checkbox changeCallback={checkboxCallback} />
      <ActiveListFilterItem
        listFilter={{ name: 'Choosen' }}
        item={{
          text: 'filter',
          value: 'value'
        }}
      />
    </div>
  );
};

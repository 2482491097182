import React from 'react';
import { styled } from 'linaria/react';
import { ProductCard } from '../CategoryPage/ProductCard';
import { SharedSwiper } from '../ui/slider/SharedSwiper';
import { theme } from '../Theme';
import { Above, Below } from '@jetshop/ui/Breakpoints';

const ProductRowWrapper = styled('section')`
  display: flex;
  justify-content: center;
  width: 100%;

  &.cartpage {
    display: block;
  }

  .max-width {
    max-width: ${theme.maxWidth};
  }

  .shared-swiper {
    /* adjusts for product card padding */
    margin: 0 -10px;

    ${theme.below.lg} {
      margin: 0;
      margin-left: 6px;
    }
  }
`;

export const ProductRow = ({ children, cartPage }) => {
  const desktopChildrenLength = children?.length < 4 ? children?.length : 4;
  const mediumChildrenLength = children.length < 2.3 ? children.length : 2.3;
  const mobileChildrenLength = children.length < 1.5 ? children.length : 1.5;
  const desktopSlidesPerView = cartPage ?? desktopChildrenLength;
  const mediumSlidesPerView = cartPage ?? mediumChildrenLength;
  const mobileSlidesPerView = cartPage ? 1.5 : mobileChildrenLength;

  // const desktopSlidesPerView = 2.5;
  // const mediumSlidesPerView = children.length < 2.3 ? children.length : 2.3;
  // const mobileSlidesPerView = children.length < 1.5 ? children.length : 1.5;
  return (
    <Above breakpoint="lg">
      {aboveLg => {
        return (
          <Below breakpoint="sm">
            {belowMd => {
              return (
                <ProductRowWrapper className={cartPage && 'cartpage'}>
                  <div className="max-width">
                    <SharedSwiper
                      slidesPerView={
                        aboveLg
                          ? desktopSlidesPerView
                          : belowMd
                          ? mobileSlidesPerView
                          : mediumSlidesPerView
                      }
                    >
                      {children}
                    </SharedSwiper>
                  </div>
                </ProductRowWrapper>
              );
            }}
          </Below>
        );
      }}
    </Above>
  );
};

export const ProductRowItem = ({ product }) => {
  return <ProductCard product={product?.value ?? product}></ProductCard>;
};

module.exports = {
  default: {
    colors: {
      /* START Radne specific colors*/
      darkred: '#D4181A',
      red: '#EB3B3B',
      lightgrey: '#F7F5F2',
      grey: '#E5E1E1',
      green: '#75D255',
      white: '#FFFFFF',
      black: '#1E1E1E',
      alphablack: '#1E1E1E1A',
      transparent: '#FFFFFF00',
      border: '#EEEEEE',
      greybackground: '#e2e2e2'
      /* END Radne specific colors*/
    },
    fontWeights: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700
    },
    breakpoints: {
      xs: '24rem',
      sm: '40rem',
      md: '50rem',
      lg: '64rem',
      xl: '80rem'
    },
    fontSizes: {
      jumbo: '40px',
      heading1: '30px',
      heading2: '24px',
      heading3: '18px',
      heading4: '14px',
      largeParagraph: '16px',
      paragraph: '13px',
      smallParagraph: '11px',
      button: '14px'
    },
    lineHeights: {
      jumbo: '40px' /* control with design spec and change */,
      heading1: '30px' /* control with design spec and change */,
      heading2: '24px' /* control with design spec and change */,
      heading3: '18px' /* control with design spec and change */,
      heading4: '16px',
      largeParagraph: '20px',
      paragraph: '17px',
      smallParagraph: '15px',
      button: '18px'
    },
    space: [
      '0px',
      '8px',
      '16px',
      '24px',
      '32px',
      '40px',
      '48px',
      '56px',
      '64px'
    ],
    fonts: {
      primary: 'Space Grotesk'
    },
    maxWidth: '88rem',
    //Change anc create valid numbers (px)
    spaceDict: {
      NONE: 0,
      XSMALL: 10,
      SMALL: 20,
      MEDIUM: 40,
      LARGE: 60,
      XLARGE: 100
    }
  }
};

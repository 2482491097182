import { styled } from 'linaria/react';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { theme } from '../Theme';
import { ReactComponent as Close } from '../../svg/Close.svg';
import { SharedSwiper } from '../ui/slider/SharedSwiper';

const CampaignBarContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CampaignBarItemContainer = styled('div')`
  background: ${props => props.backgroundcolor};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  padding: 0 0.5rem;

  ${theme.below.lg} {
    min-height: 30px;
    text-align: center;
    padding: 0px 25px;
  }

  a,
  p {
    font-style: normal;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.08em;
    text-decoration: none;
    font-weight: 400;
    color: ${props => props.textcolor};
    ${theme.below.lg} {
      font-size: 14px;
    }
  }
`;

const CloseBtn = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: ${props => props.closeButtonColor};

  .icon-wrapper {
    margin-right: 11px;

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 16px !important;
      height: auto;
    }
  }
`;

const SwiperContainer = styled('div')`
  position: relative;
  height: 30px;
  max-height: 0px;
  min-height: 30px;

  .slider-button {
    display: none;
  }

  .shared-swiper-wrapper {
    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          .campaign-bar-item {
            opacity: 0;
          }
        }
      }
    }
  }
  &.is-mounted {
    max-height: 30px;
    .shared-swiper-wrapper {
      .swiper {
        .swiper-wrapper {
          .swiper-slide {
            .campaign-bar-item {
              opacity: 1;
            }
          }
        }
      }
    }
  }
`;

export const CampaignBarItem = ({ backgroundColor, link, text, textColor }) => {
  return (
    <CampaignBarItemContainer
      backgroundcolor={backgroundColor?.value}
      textcolor={textColor?.value}
      className="campaign-bar-item"
    >
      {link?.value?.length > 0 ? (
        <Link
          to={link?.value}
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      )}
    </CampaignBarItemContainer>
  );
};

export const CampaignBar = React.memo(
  ({ closeCampaignBarOption, closeButtonColor, children }) => {
    const [mounted, setMounted] = useState();
    const [cookies, setCookie] = useCookies();
    const hideCampaignBar = cookies?.hideCampaignBar;
    const showCloseBtn = closeCampaignBarOption?.value === 'YES';

    const handleCloseClick = () => {
      setCookie('hideCampaignBar', true, [{ path: '/' }]);
    };

    const CloseButton = () =>
      showCloseBtn ? (
        <CloseBtn
          closeButtonColor={closeButtonColor?.value}
          onClick={() => {
            handleCloseClick();
          }}
        >
          <div className={'icon-wrapper'}>
            <Close />
          </div>
        </CloseBtn>
      ) : null;

    useEffect(() => {
      if (!mounted) {
        setMounted(true);
      }
    }, []);

    return (
      <>
        {!hideCampaignBar &&
          (children.length > 1 ? (
            <SwiperContainer
              id="campaign-bar"
              className={mounted && 'is-mounted'}
            >
              <CloseButton />
              <SharedSwiper shouldAutoplay={true} full={true}>
                {children}
              </SharedSwiper>
            </SwiperContainer>
          ) : (
            <CampaignBarContainer id="campaign-bar">
              <CloseButton />
              {children}
            </CampaignBarContainer>
          ))}
      </>
    );
  }
);

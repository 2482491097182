import Breadcrumbs from '@jetshop/ui/Breadcrumbs';
import React from 'react';
import { styled } from 'linaria/react';

const StyledBreadcrumbsWrapper = styled('div')`
  ul {
    margin: 0;
  }
  li,
  a {
    font-size: 14px;
    line-height: 1.2;
    text-decoration: none;
  }

  li:after {
    display: none;
  }

  li:not(:first-child) {
    :before {
      display: inline-block;
      content: '/';
      padding: 0 8px;
    }
  }
`;

export const StyledBreadcrumbs = ({ breadcrumbText, breadcrumbs, parents }) => {
  const breadcrumbProps = {
    breadcrumbText,
    breadcrumbs,
    parents
  };

  return (
    <StyledBreadcrumbsWrapper>
      <Breadcrumbs {...breadcrumbProps} />
    </StyledBreadcrumbsWrapper>
  );
};

// export const StyledBreadcrumbs = ({ ...props }) => {
//   return (
//     <StyledBreadcrumbsWrapper>
//       <Breadcrumbs {...props} />
//     </StyledBreadcrumbsWrapper>
//   );
// };

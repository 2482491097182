import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import ChannelSelector, {
  SelectorBody
} from '@jetshop/ui/ChannelSelector/ChannelSelector';
import LargeSelector from '@jetshop/ui/ChannelSelector/LargeSelector';
import { theme } from '../../../Theme';
import { ReactComponent as Global } from '../../../../svg/Global.svg';

const Wrapper = styled('div')`
  & > div:first-child {
    border: 1px solid #e1e1e1;
    background: #ffffff;
  }
`;

const CountryFlag = styled('img')`
  height: 12px;
  width: 16px;
  margin-right: 15px;
`;

const StyledChannelSelector = styled(ChannelSelector)`
  border: 1px solid #e1e1e1;
`;

const StyledSelectorBody = styled(SelectorBody)`
  padding: 0;

  svg {
    height: 12px;
    width: 16px;
    margin-right: 15px;
  }
`;

const ChannelInner = styled('div')`
  transition: 200ms;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;

  h4 {
    color: ${theme.colors.black};
    opacity: 0.4;
    margin: 0;
  }

  ${theme.below.lg} {
    height: 35px;
  }

  svg {
    height: auto;
    width: 30px;
    margin-right: 16px;
    ${theme.below.lg} {
      width: 20px;
    }
  }

  img,
  svg {
    opacity: 0.75;
  }

  :hover {
    background: #f7f7f7;
    img,
    svg {
      opacity: 1;
    }
  }

  &.active {
    h4 {
      opacity: 1;
    }
    img {
      opacity: 1;
    }
  }
`;

const Selector = ({ channels, selectedChannel, updateChannel }) => {
  let activeChannels;
  const activeChannelsB2C = [1, 2, 3, 4];
  const activeChannelsB2B = [6, 7, 8];

  // IS B2C CHANNEL
  if (activeChannelsB2C.includes(selectedChannel?.id)) {
    activeChannels = activeChannelsB2C;
  }
  // IS B2B CHANNEL
  if (activeChannelsB2B.includes(selectedChannel?.id)) {
    activeChannels = activeChannelsB2B;
  }

  return (
    <StyledChannelSelector
      channels={channels}
      initialChannel={selectedChannel}
      render={({ channels, selectedChannel, onSelect }) => (
        <Wrapper>
          <LargeSelector>
            <StyledSelectorBody>
              {channels &&
                channels.map(channel => {
                  return (
                    <React.Fragment key={channel.id}>
                      {activeChannels.includes(channel.id) ? (
                        <ChannelInner
                          key={channel.id}
                          className={
                            channel.id === selectedChannel.id ? 'active' : null
                          }
                          onClick={() => {
                            const chosenChannel = onSelect(
                              channel.id,
                              channel.defaultLanguage.culture,
                              channel.defaultCurrency.id
                            );
                            updateChannel(chosenChannel);
                          }}
                        >
                          {channel.id !== 4 ? (
                            <CountryFlag
                              src={`https://countryflags.jetshop.io/${channel.countries[0].code}/flat/32.png`}
                              alt={t('Country Flag')}
                            />
                          ) : (
                            <Global />
                          )}
                          {channel.displayName}
                        </ChannelInner>
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </StyledSelectorBody>
          </LargeSelector>
        </Wrapper>
      )}
    />
  );
};

export default Selector;

import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { Heading3 } from '../ui/text/Heading3';
import { PushPrice } from '../ui/StyledPrice';
import { theme } from '../Theme';
import { ContentPlacer } from './ContentPlacer';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import { StockStatusHolder } from '../ui/StockStatusHolder';

const PushMaxWidth = styled('section')`
  max-width: ${theme.maxWidth};
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  .push-row-item {
    height: 100%;
  }

  .child-0 {
    grid-area: 1 / 1 / 2 / 13;
    aspect-ratio: 7 / 4;
  }

  .child-1 {
    grid-area: 1 / 13 / 2 / 22;
  }

  .child-2 {
    grid-area: 2 / 4 / 3 / 11;
  }

  .child-3 {
    grid-area: 2 / 11 / 3 / 18;
  }

  .child-4 {
    grid-area: 2 / 18 / 3 / 25;
  }

  ${theme.below.lg} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    padding: 0 1rem;

    .child-0 {
      grid-area: 1 / 1 / 3 / 3;
      aspect-ratio: 1;

      .heading-3 {
        font-size: ${theme.fontSizes.paragraph};
        line-height: ${theme.lineHeights.paragraph};
      }

      [data-flight-price] {
        .price,
        .new-price {
          font-size: 40px;
          line-height: 40px;
        }

        .percentage,
        .old-price {
          font-size: ${theme.fontSizes.paragraph};
        }
      }
    }

    .child-1 {
      grid-area: 3 / 1 / 4 / 2;
    }

    .child-2 {
      grid-area: 3 / 2 / 4 / 3;
    }

    .child-3 {
      grid-area: 4 / 1 / 5 / 2;
    }

    .child-4 {
      grid-area: 4 / 2 / 5 / 3;
    }
  }
`;

export const PushRow = ({ children }) => {
  return (
    <PushMaxWidth>
      {children.map((child, index) => {
        return (
          <div key={`push-row-item-${index}`} className={`child-${index}`}>
            <PushRowItem {...child?.props} />
          </div>
        );
      })}
    </PushMaxWidth>
  );
};

const PushRowItemWrapper = styled('div')`
  background: ${theme.colors.lightgrey};
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .link-evaluator {
    display: flex;
    flex-direction: column;
  }

  img {
    mix-blend-mode: darken;
  }

  .push-content {
    position: relative;
    display: flex;
    height: 100%;

    .content-placer {
      padding: 20px;

       {
        /*Fix to make blend-mode work after adding TintLayer */
      }
      > * {
        z-index: unset;
      }

      ${theme.below.lg} {
        padding: 10px;
      }
    }
  }

  .stockstatus-row {
    padding: 0px 20px 20px 20px;
    ${theme.below.lg} {
      padding: 0px 10px 10px 10px;
    }
  }
`;

const PushRowImageWrapper = styled('div')`
  width: 100%;
  max-width: 260px;

  ${theme.below.lg} {
    max-width: 70%;
  }

  img {
    mix-blend-mode: darken;
  }
`;

const ProductInfoWrapper = styled('div')`
  width: 50%;
  height: fit-content;
  max-width: 180px;
  text-shadow: 0 0 0.2em ${theme.colors.lightgrey},
    0 0 0.2em ${theme.colors.lightgrey}, 0 0 0.2em ${theme.colors.lightgrey};

  .percentage {
    text-shadow: none;
  }

  &.sale {
    .heading-3 {
      margin-left: 20px;
      max-width: 150px;
    }
  }

  ${theme.below.lg} {
    .heading-3 {
      font-size: ${theme.fontSizes.smallParagraph};
      line-height: 13px;//${theme.lineHeights.smallParagraph};
    }

    .price,
    .new-price {
      font-size: 25px;
      line-height: 25px;
    }


  }
`;

export const PushRowItem = ({
  product,
  imageHorizontalPosition,
  imageVerticalPosition,
  contentHorizontalPosition,
  contentVerticalPosition
}) => {
  const imageAspect = '1:1';
  const imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4];

  const isOnSale =
    product?.value?.price?.incVat < product?.value?.previousPrice?.incVat;

  return (
    <PushRowItemWrapper className="push-row-item">
      <LinkEvaluator link={product?.value?.primaryRoute?.path}>
        <div className="push-content">
          <ContentPlacer
            horizontalPosition={imageHorizontalPosition}
            verticalPosition={imageVerticalPosition}
          >
            <PushRowImageWrapper className="push-row-image-wrapper">
              <Image
                cover
                src={product?.value?.images?.[0]?.url}
                alt={product?.value?.images?.[0]?.alt}
                modifiedDate={product?.value?.images?.[0]?.modifiedDate}
                aspect={imageAspect}
                sizes={imageSizes}
              />
            </PushRowImageWrapper>
          </ContentPlacer>
          <ContentPlacer
            horizontalPosition={contentHorizontalPosition}
            verticalPosition={contentVerticalPosition}
          >
            <ProductInfoWrapper className={isOnSale && 'sale'}>
              <Heading3 text={product?.value?.name} />
              <PushPrice
                price={product?.value?.price}
                previousPrice={product?.value?.previousPrice}
              />
            </ProductInfoWrapper>
          </ContentPlacer>
        </div>
        <div className="stockstatus-row">
          {product?.value?.stockStatus?.text && (
            <StockStatusHolder product={product.value} />
          )}
        </div>
      </LinkEvaluator>
    </PushRowItemWrapper>
  );
};

import React from 'react';
import { styled } from 'linaria/react';

import { ReactComponent as Visa } from '../../svg/Visa.svg';
import { ReactComponent as Mastercard } from '../../svg/Mastercard.svg';
import { ReactComponent as Paypal } from '../../svg/Paypal.svg';
import { ReactComponent as Amex } from '../../svg/AMEX.svg';
import { ReactComponent as ApplePay } from '../../svg/ApplePay.svg';
import { ReactComponent as Klarna } from '../../svg/Klarna.svg';
import KlarnaPaymentInfo from '../ProductPage/KlarnaPayementInfo';

const PaymentRowWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  .payment-row-inner {
    display: flex;
    justify-content: center;

    svg {
      width: 30px;
      height: 20px;
    }
  }
`;

export const PaymentRow = ({ klarna, children }) => {
  return (
    <PaymentRowWrapper className="payment-row-wrapper">
      <KlarnaPaymentInfo />
      <div className="payment-row-inner">{children}</div>
    </PaymentRowWrapper>
  );
};

export const PaymentRowItemWrapper = styled('div')`
  margin: 0 10px;
`;

export const PaymentRowItem = ({ icon }) => {
  const iconSwitch = iconName => {
    switch (iconName) {
      case 'VISA':
        return <Visa />;
      case 'MASTERCARD':
        return <Mastercard />;
      case 'PAYPAL':
        return <Paypal />;
      case 'KLARNA':
        return <Klarna />;
      case 'APPLEPAY':
        return <ApplePay />;
      case 'AMEX':
        return <Amex />;

      default:
        return null;
    }
  };

  return (
    <PaymentRowItemWrapper className="payment-item">
      {iconSwitch(icon?.value)}
    </PaymentRowItemWrapper>
  );
};

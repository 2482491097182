import loadable from '@loadable/component';
import React from 'react';
import Image from '@jetshop/ui/Image';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../../Theme';
import { VariantSelector } from '../../ProductPage/VariantSelector';
import { QtyAdjuster } from '../../ui/AddToCartQtyButton';
import { PushPrice } from '../../ui/StyledPrice';
import { Paragraph } from '../../ui/text/Paragraph';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import { useProductValidationMessage } from '../../ProductPage/useProductValidationMessage';
import StockStatusIndicator from '../../ProductPage/StockStatus/StockStatusIndicator';
import { useAddToCart } from '../../../hooks/useAddToCart';
import { StyledButton } from '../../ui/StyledButton';
import t from '@jetshop/intl';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import { useTrackProductAdd } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import Spinner from '../../ui/Spinner';

const QuickBuyItemWrapper = styled('section')`
  border-bottom: 1px solid ${theme.colors.border};
  margin-bottom: 20px;
  padding-bottom: 20px;

  .size-guide-and-stockstatus {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
  }

  .styled-button-wrapper {
    width: 100%;
  }
`;

const ProductInfoRow = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  .image-wrapper {
    flex: 1;
    background: ${theme.colors.lightgrey};

    [data-flight-image-container] {
      mix-blend-mode: darken;
    }
  }

  .product-info {
    margin-left: 10px;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top-row {
      p {
        margin-bottom: 2px;
      }
    }

    .bottom-row {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .qty-adjuster {
        max-width: 100px;

        button,
        input {
          width: calc(100% / 3);
          font-size: 13px !important;
        }
        button {
          height: 32px;

          svg {
            height: 11px;
            width: 11px;
          }
        }
      }

      [data-flight-price] {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: end;
        .price,
        .new-price {
          font-size: ${theme.fontSizes.largeParagraph};
          line-height: ${theme.fontSizes.largeParagraph};
          color: ${theme.colors.darkred};
          text-align: end;
        }

        .percentage,
        .old-price {
          font-size: ${theme.fontSizes.smallParagraph};
        }
      }
    }
  }
`;

const LoadablePackageProduct = loadable(
  () => import('../../ProductPage/PackageProduct/NewPackageProduct'),
  {
    fallback: <Spinner />
  }
);

/**
 * The QuickBuyItem function renders a product in the QuickBuy component.
 *
 * @param {object} quickBuyProduct Pass the product to the quickbuyitem component
 * @param {function} hideTarget Hide the quick buy modal once the user has made a purchase or closes flyout
 * @param {function} setQuickBuyQty Set the quick buy qty from the qtyadjuster
 * @param {number} quickBuyQty Set the initial value of the qtyadjuster
 *
 * @return A component with variants and buy button
 *
 * @docauthor Anders Zetterström @ R3
 */
export const QuickBuyItem = ({
  quickBuyProduct,
  hideTarget,
  setQuickBuyQty,
  quickBuyQty
}) => {
  const variantHandler = useProductVariants(quickBuyProduct, {
    preselectCheapest: true
  });
  const {
    selectedVariant: selectedVariation,
    variantValidation,
    missingOptions
  } = variantHandler;

  const stockStatus = useStockStatus(selectedVariation ?? quickBuyProduct);

  const { hasVariants } = selectedVariation ?? quickBuyProduct;

  const { validationMessage } = useProductValidationMessage({
    missingOptions,
    variantValidation,
    hasVariants,
    stockStatus
  });

  const trackAddProductVariant = useTrackProductAdd(
    selectedVariation ?? quickBuyProduct
  );

  const trackAddToCart = () => {
    trackAddProductVariant(quickBuyProduct, quickBuyQty);
  };
  const { addProduct } = useAddToCart(trackAddToCart);
  const isPackage = quickBuyProduct?.isPackage;

  if (isPackage) {
    return (
      <>
        <h2>{quickBuyProduct?.name}</h2>
        <LoadablePackageProduct
          product={quickBuyProduct}
          hideTarget={hideTarget}
        />
      </>
    );
  }

  return (
    <QuickBuyItemWrapper>
      <ProductInfoRow>
        <div className="image-wrapper">
          <Image
            sizes={[1 / 10]}
            aspect={'1:1'}
            alt={quickBuyProduct?.images?.[0]?.alt}
            src={quickBuyProduct?.images?.[0]?.url}
          />
        </div>
        <div className="product-info">
          <div className="top-row">
            <Paragraph text={quickBuyProduct?.subName} weight={'bold'} />
            <Paragraph text={quickBuyProduct?.name} />
          </div>

          <div className="bottom-row">
            <QtyAdjuster qty={quickBuyQty} setQty={setQuickBuyQty} />
            <PushPrice
              price={quickBuyProduct?.price}
              previousPrice={quickBuyProduct?.previousPrice}
            />
          </div>
        </div>
      </ProductInfoRow>
      {quickBuyProduct?.hasVariants && (
        <VariantSelector
          product={quickBuyProduct}
          variantHandler={variantHandler}
          showValidation={!!validationMessage}
          disableOutOfStock
        />
      )}
      <div className="size-guide-and-stockstatus">
        <StockStatusIndicator
          status={stockStatus?.status}
          text={stockStatus?.text}
        />
      </div>

      <StyledButton
        className={cx('RED', !!validationMessage && 'disabled')}
        clickCallback={() => {
          if (!validationMessage) {
            addProduct(selectedVariation, quickBuyQty);
            hideTarget();
          }
        }}
      >
        <>{validationMessage ? validationMessage : t('Add to cart')}</>
      </StyledButton>
    </QuickBuyItemWrapper>
  );
};

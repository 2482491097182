import { ContentEditorButton } from '../ui/StyledButton';
import { DynamicBoxRow } from './DynamicBoxRow';
import { ImageBoxRow, ImageBoxRowItem } from './ImageBoxRow';
import { ImageHero } from './ImageHero';
import { ProductRow, ProductRowItem } from './ProductRow';
import { PushRow, PushRowItem } from './PushRow';
import { Spacer } from './Spacer';
import { CampaignBar, CampaignBarItem } from './CampaignBar';
import { Title } from './Title';
import { MenuContent, MenuContentItem } from './MenuContent';
import { DoubleRow } from './DoubleRow';
import { ExploreRow, ExploreRowItem } from './ExploreRow';
import { UspItem, Usps } from '../ProductPage/Usps';
import { PaymentRow, PaymentRowItem } from '../Cart/PaymentRow';
import { Seo, SeoItem } from './Seo';
import { HelloRetailContentComponent } from './HelloRetailContentComponent';
import { HtmlComponent, HtmlRow } from './HtmlRow';
import { FooterLinksColumn, FooterLinksColumnItem } from './FooterLinksColumn';
import { ExplodedView, ExplodedProduct } from './ExplodedView';
import { ScriptLoader } from './ScriptLoader';
import { PlaceholderChildComponent } from './PlaceholderChildComponent';
import { ContentColumns } from './ContentColumns';

export const allContentComponents = {
  IMAGEHERO: ImageHero,
  BUTTON: ContentEditorButton,
  SPACER: Spacer,
  CAMPAIGNBAR: CampaignBar,
  CAMPAIGNBARITEM: CampaignBarItem,
  TITLE: Title,
  IMAGEBOXROW: ImageBoxRow,
  IMAGEBOXROWITEM: ImageBoxRowItem,
  PRODUCTROW: ProductRow,
  PRODUCTROWITEM: ProductRowItem,
  PUSHROW: PushRow,
  PUSHROWITEM: PushRowItem,
  DYNAMICBOXROW: DynamicBoxRow,
  DOUBLECONTENTROW: DoubleRow,
  EXPLOREROW: ExploreRow,
  EXPLOREROWITEM: ExploreRowItem,
  USPROW: Usps,
  USPITEM: UspItem,
  PAYMENTROW: PaymentRow,
  PAYMENTROWITEM: PaymentRowItem,
  HELLORETAILPRODUCTROW: HelloRetailContentComponent,
  HTMLROW: HtmlRow,
  RAWHTML: HtmlComponent,
  WYSIWYG: HtmlComponent,
  EXPLODEDVIEW: ExplodedView,
  EXPLODEDPRODUCT: ExplodedProduct,
  SCRIPTLOADER: ScriptLoader,
  SCRIPTLOADERATTRIBUTE: PlaceholderChildComponent,
  CONTENTCOLUMNS: ContentColumns
};

export const menuContentComponents = {
  MENUCONTENT: MenuContent,
  MENUCONTENTITEM: MenuContentItem
};

export const seoComponents = {
  SEO: Seo,
  SEOITEM: SeoItem
};

export const footerComponents = {
  FOOTERLINKSCOLUMN: FooterLinksColumn,
  FOOTERCOLUMNLINKITEM: FooterLinksColumnItem
};

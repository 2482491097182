import React from 'react';
import { cx, css } from 'linaria';
import { theme } from '../Theme';

const contentColumnsStyle = css`
  width: 100%;
  max-width: ${theme.maxWidth};
  margin: auto;
  display: flex;

  &.fullwidth {
    max-width: 100%;
  }

  ${theme.below.lg} {
    flex-direction: column;
  }
`;

export const ContentColumns = ({
  fullWidth,
  backgroundColor,
  boxPadding,
  textColor,
  children
}) => {
  return (
    <div
      className={cx(
        'content-columns-wrapper',
        contentColumnsStyle,
        fullWidth.value && 'fullwidth'
      )}
      style={{
        background: backgroundColor?.value,
        color: textColor?.value,
        columns: children?.length,
        padding: theme.spaceDict[boxPadding?.value]
      }}
    >
      {children}
    </div>
  );
};

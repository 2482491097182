import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { DynamicBoxRow } from './DynamicBoxRow';
import { Paragraph } from '../ui/text/Paragraph';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import Image from '@jetshop/ui/Image';
import { Below } from '@jetshop/ui/Breakpoints';

const ExploreRowWrapper = styled('section')`
  .dynamic-box-container {
    background: ${theme.colors.white};
  }
`;

export const ExploreRow = ({ children }) => {
  return (
    <Below breakpoint="lg">
      {matches => (
        <ExploreRowWrapper>
          <DynamicBoxRow
            rowPadding={0}
            sliderProps={matches && { slidesPerView: 3 }}
          >
            {children}
          </DynamicBoxRow>
        </ExploreRowWrapper>
      )}
    </Below>
  );
};

const ExploreRowItemWrapper = styled('div')`
  .paragraph,
  a {
    color: ${theme.colors.black};
  }

  .paragraph {
    margin-top: 20px;
    text-transform: uppercase;
    ${theme.below.lg} {
      margin-top: 10px;
    }
  }
`;

export const ExploreRowItem = ({ image, link, text }) => {
  const sizes = [1 / 3, 1 / 3, 1 / 4, 1 / 4];
  return (
    <ExploreRowItemWrapper className="explore-row-item">
      <LinkEvaluator link={link}>
        <Image src={image.value} aspect={'33:41'} cover sizes={sizes} />
        <Paragraph text={text.value} />
      </LinkEvaluator>
    </ExploreRowItemWrapper>
  );
};

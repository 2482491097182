import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import useProductToast from './useProductToast';
import { theme } from '../../Theme';
import cartQuery from '../../Cart/queries/cartQuery.gql';
import { PushPrice } from '../../ui/StyledPrice';

export const Container = styled('aside')`
  ${theme.above.sm} {
    width: 320px;
  }
  ${theme.below.sm} {
    width: 100%;
  }
  background-color: ${theme.colors.white};
`;

export const ProductImageWrapper = styled('div')`
  width: 5rem;
  margin-right: 1rem;
  background: ${theme.colors.lightgrey};

  img {
    mix-blend-mode: darken;
  }
`;

export const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  a {
    text-decoration: none;
    :hover {
      opacity: 0.8;
      text-decoration: none;
      color: white;
    }
  }
`;

export const Product = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: ${theme.space[2]};
`;

export const ProductDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: black;

  .push-style {
    &.price,
    &.new-price {
      font-size: 30px;
      line-height: 35px;
    }

    &.new-price {
      margin-left: 0;
    }

    &.old-price {
      font-size: 12px;
    }
  }
  .red-tilt-wrapper {
    .percentage {
      font-size: 11px;
    }
  }
`;

export const Header = styled('h3')`
  font-size: 16px;
  margin-bottom: ${theme.space[1]};
`;

export const ProductName = styled('p')`
  font-size: 14px;
  font-weight: 400;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Error = styled('p')`
  color: red;
  margin-top: ${theme.space[1]};
  font-size: 14px;
`;

const ProductToast = ({
  product,
  selectedVariation,
  quantity,
  error,
  priceSelected,
  qtySelected
}) => {
  const { price, previousPrice, image } = useProductToast({
    product,
    selectedVariation,
    quantity
  });
  const [currentPrice, setCurrentPrice] = useState(price);
  useEffect(() => {
    if (priceSelected) {
      const newPrice = {
        exVat: priceSelected.price.exVat,
        incVat: priceSelected.price.incVat,
        vat: priceSelected.price.vat
      };
      setCurrentPrice(newPrice);
    }
  }, [priceSelected, qtySelected]);

  return (
    <Container data-testid="product-toast">
      <ProductCheckoutContainer>
        <Product>
          {product?.images?.length > 0 && (
            <ProductImageWrapper>
              <Image
                src={image.url}
                sizes={80}
                aspect={'1:1'}
                alt={image.alt}
                quality={80}
              />
            </ProductImageWrapper>
          )}
          <ProductDetails>
            {error ? (
              <Header>{t('Failed adding to bag')}</Header>
            ) : quantity === 1 ? (
              <Header>{t('Added to bag')}</Header>
            ) : (
              <Header>
                {t.rich('Added {quantity} items to bag', { quantity })}
              </Header>
            )}
            <ProductName>{product.name}</ProductName>
            {!error && (
              <PushPrice price={currentPrice} previousPrice={previousPrice} />
            )}
            {/* {!error && <Price price={price} previousPrice={previousPrice} />} */}
            {error && (
              <Error>
                {t('An error occurred. Details:')}
                <ul>
                  {error.graphQLErrors && error.graphQLErrors.length > 0 ? (
                    error.graphQLErrors.map(({ message, locations, path }) => (
                      <li key={message}>{t(message)}</li>
                    ))
                  ) : (
                    <li>{t(error.message)}</li>
                  )}
                </ul>
              </Error>
            )}
          </ProductDetails>
        </Product>
      </ProductCheckoutContainer>
    </Container>
  );
};
const ProductToastWrapper = props => (
  <CartProvider query={cartQuery}>
    {({ data }) => <ProductToast {...props} cart={data && data.cart} />}
  </CartProvider>
);

export default ProductToastWrapper;

import React from 'react';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { useQuery } from 'react-apollo';
import { allContentComponents } from './ContentComponents';
import Spinner from '../ui/Spinner';
import { styled } from 'linaria/react';
import ContentPageContentQuery from '../ContentPage/ContentPageContentQuery.gql';
import CategoryContentQuery from './CategoryContentQuery.gql';
import {
  insertPropToRootComponents,
  insertPropToComponentsChildren
} from '../../utils/HelpFunctions';

const SpinnerWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: black;
  }
`;

const DynamicContentRendererWrapper = styled('div')``;

export const DynamicPageContentRenderer = ({ pageId, rendererComponents }) => {
  const { data, loading, error } = useQuery(ContentPageContentQuery, {
    variables: {
      id: pageId
    }
  });

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  if (!data || error) {
    return null;
  }
  const components = rendererComponents ?? allContentComponents;
  const items = data?.page?.data?.items;

  return (
    <DynamicContentRendererWrapper className="dynamic-wrapper">
      {items && <ContentRenderer items={items} components={components} />}
    </DynamicContentRendererWrapper>
  );
};

export const DynamicCategoryContentRenderer = ({
  categoryId,
  rendererComponents,
  extraRootProps,
  extraItemProps
}) => {
  const { data, loading, error } = useQuery(CategoryContentQuery, {
    variables: {
      id: categoryId
    }
  });

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  if (!data || error) {
    return null;
  }
  const components = rendererComponents ?? allContentComponents;
  const items = data?.category?.data?.items;

  let renderItems = items;

  if (extraRootProps) {
    renderItems = insertPropToRootComponents(renderItems, extraRootProps);
  }

  if (extraItemProps) {
    renderItems = insertPropToComponentsChildren(renderItems, extraItemProps);
  }

  return (
    <DynamicContentRendererWrapper className="dynamic-wrapper">
      {items && <ContentRenderer items={renderItems} components={components} />}
    </DynamicContentRendererWrapper>
  );
};

import React, { createElement } from 'react';
import Image from '@jetshop/ui/Image';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import { BoxContent } from './BoxContent';
import { theme } from '../Theme';
import { StyledButton } from '../ui/StyledButton';
import { Above } from '@jetshop/ui/Breakpoints';

const ImageBoxRowWrapper = styled('section')`
  position: relative;
  padding: 0;
  width: 100%;
  max-width: ${theme.maxWidth};
  margin: auto;
  display: flex;
  gap: ${props => props.gap}px;

  ${theme.below.lg} {
    flex-direction: column;
    padding: 0 ${props => props.gap}px;
  }

  &.fullwidth {
    max-width: 100%;
  }
`;

export const ImageBoxRow = ({ fullWidth, boxPadding, children }) => {
  return (
    <ImageBoxRowWrapper
      gap={theme.spaceDict[boxPadding?.value ?? '0']}
      className={cx(fullWidth?.value && 'fullwidth')}
    >
      {children}
    </ImageBoxRowWrapper>
  );
};

const ImageBoxRowItemWrapper = styled('div')`
  position: relative;

  height: 100%;
  flex: 1;
`;

export const ImageBoxRowItem = ({
  image,
  imageRatio,
  mobileImage,
  mobileImageRatio,
  tintLayer,
  link,
  textColor,
  header,
  preHeader,
  textAlignment,
  buttonText,
  buttonTheme,
  horizontalPosition,
  verticalPosition
}) => {
  const imageBtn = createElement(
    StyledButton,
    {
      key: 'image-box-item-button',
      className: `image-box-button ${buttonTheme?.value ?? 'RED'}`
    },
    buttonText?.value
  );

  const imageSizes = [1, 1, 1, 300, 600];

  return (
    <Above breakpoint="md">
      {matches => (
        <ImageBoxRowItemWrapper>
          <LinkEvaluator link={link}>
            <Image
              sizes={imageSizes}
              src={matches ? image?.value : mobileImage?.value}
              aspect={matches ? imageRatio?.value : mobileImageRatio?.value}
              cover
            />
            <BoxContent
              verticalPosition={verticalPosition}
              horizontalPosition={horizontalPosition}
              tintLayer={tintLayer}
              color={textColor}
              preHeader={preHeader}
              header={header}
              textAlignment={textAlignment}
              buttonChildren={buttonText?.value ? [imageBtn] : null}
              forType={'image-box'}
            />
          </LinkEvaluator>
        </ImageBoxRowItemWrapper>
      )}
    </Above>
  );
};

import React, { useState, useEffect } from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { LargeParagraph } from './text/LargeParagraph';

const CheckboxWrapper = styled('div')`
  .large-paragraph {
    display: inline-block;
  }

  input[type='checkbox'] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .checkbox-span {
    display: inline-block;
    position: relative;
    width: 11px;
    height: 11px;
    background: ${theme.colors.transparent};
    outline: 1px solid ${theme.colors.black};
    border-radius: 2px;
    margin-right: 6px;
  }

  .checkbox--active {
    .box-fill {
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      border-radius: 1px;
      background: ${theme.colors.red};
    }
  }
`;

export const Checkbox = ({ text, initialValue = false, changeCallback }) => {
  const [checked, setChecked] = useState(initialValue);

  useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);
  return (
    <CheckboxWrapper>
      <label className="checkbox-label">
        <input
          className={'checkbox-input'}
          type={'checkbox'}
          checked={checked}
          onChange={() => {
            changeCallback(!checked);
            setChecked(!checked);
          }}
        />
        <span
          className={cx('checkbox-span', checked && 'checkbox--active')}
          aria-hidden="true"
        >
          <span className="box-fill" />
        </span>
        {text && <LargeParagraph text={text} />}
      </label>
    </CheckboxWrapper>
  );
};

import { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const useChannelCheck = () => {
  const { selectedChannel } = useContext(ChannelContext);
  const b2bList = [6, 7, 8];

  const isB2B = b2bList?.includes(selectedChannel?.id);
  const isB2C = !isB2B;
  const isRocketEngine = selectedChannel?.id === 5;

  return { isB2B, isB2C, isRocketEngine };
};

export default useChannelCheck;

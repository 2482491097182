import React from 'react';
import { ReactComponent as StockSVG } from '../../svg/Stock.svg';
import { cx, css } from 'linaria';

const stockColors = {
  inStock: '#27AE60',
  outOfStock: '#EB5757',
  notifyWhenBack: '#F2C94C'
};

const stockIconStyles = css`
  margin-right: 5px;
  &.inStock {
    color: ${stockColors['inStock']};
  }
  &.outOfStock {
    color: ${stockColors['outOfStock']};
  }
  &.notifyWhenBack {
    color: ${stockColors['notifyWhenBack']};
  }
  &.missingVariant {
    color: ${stockColors['outOfStock']};
  }
`;

export const StockIcon = ({ status }) => {
  return (
    <StockSVG className={cx(stockIconStyles, 'stock-icon', `${status}`)} />
  );
};

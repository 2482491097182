import { useState, useEffect } from 'react';

// Hook
let cachedScripts = [];

/**
 * The useScriptLoader function is a React hook that loads external JavaScript files.

 * It accepts an object with the following properties:
 * @param {Object} obj object with parameters for different configuration
 * @param {string} obj.src Src to script
 * @param {Object} obj.attribrutes Object with script tag attributes
 * @param {Object} obj.ref Ref to place script where the ref is located
 * @param {boolean} obj.noCache Option to make script load again
 * 
 * @return Object containing loaded and error
 *
 * @docauthor Anders Zetterstrom @ R3
 */
export function useScriptLoader({ src, attributes, ref, noCache = false }) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
    mounted: false
  });

  useEffect(() => {
    // If cachedScripts array already includes src that means another instance ...
    // ... of this hook already loaded this script, so no need to load again.
    if (state.mounted) return;
    if (cachedScripts.includes(src) && !noCache) {
      setState({
        loaded: true,
        error: false
      });
    } else {
      cachedScripts.push(src);

      // Create script
      let script = document.createElement('script');
      script.src = src;
      script.async = true;

      // if attribrutes exists, add them
      if (
        !(
          Object.keys(attributes).length === 0 &&
          attributes.constructor === Object
        )
      ) {
        for (const key in attributes) {
          script.setAttribute(key, attributes[key]);
        }
      }

      // Script event listener callbacks for load and error
      const onScriptLoad = () => {
        setState({
          loaded: true,
          error: false
        });
      };

      const onScriptError = () => {
        // Remove from cachedScripts we can try loading again
        const index = cachedScripts.indexOf(src);
        if (index >= 0) cachedScripts.splice(index, 1);
        script.remove();

        setState({
          loaded: true,
          error: true
        });
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      if (ref) {
        // Add script to ref
        ref?.current.appendChild(script);
      } else {
        // Add script to document body
        document.body.appendChild(script);
      }
      setState({ ...state, mounted: true });

      // Remove event listeners on cleanup
      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  }, [src, ref, ref?.current]);

  return { loaded: state.loaded, error: state.error };
}

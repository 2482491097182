import React from 'react';
import { theme } from '../../Theme';
import { cx, css } from 'linaria';
import { fontWeightsStyles } from '../sharedStyles/FontWeightStyles';

export const largeParagraphStyles = css`
  font-size: ${theme.fontSizes.largeParagraph};
  line-height: ${theme.lineHeights.largeParagraph};
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colors.black};
`;

/**
 * The LargeParagraph function is a component that renders text.
 *
 *
 * @param {String} weight  Set the weight of the paragraph (select classnames from fontWeightsStyles)
 * @param {String} text Text to be presented in component.
 * @return A paragraph element with a class of paragraph and the font-weight styles
 *
 * @docauthor Anders Zetterström @ R3
 */
export const LargeParagraph = ({ weight, text }) => {
  return (
    <p
      className={cx(
        'large-paragraph',
        largeParagraphStyles,
        fontWeightsStyles,
        weight
      )}
      dangerouslySetInnerHTML={{
        __html: text
      }}
    />
  );
};

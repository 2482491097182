import React from 'react';
import { theme } from '../../Theme';
import { cx, css } from 'linaria';
import { fontWeightsStyles } from '../sharedStyles/FontWeightStyles';

export const heading2Styles = css`
  font-size: ${theme.fontSizes.heading2};
  line-height: ${theme.lineHeights.heading2};
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colors.black};
  text-align: center;
  padding: 0 14px;
`;

/**
 * The Heading2 function is a component that accepts a weight prop and text.
 * The Heading2 function returns JSX that renders an h2 element with the given weight.
 *
 * @param {String} weight Set the font weight of the heading
 * @param {String} text Text to be presented in component
 *
 * @return A h2 element with a class of heading-2
 *
 * @docauthor Anders Zetterström @ R3
 */
export const Heading2 = ({ weight, text, fontSize }) => {
  return (
    <h2
      className={cx('heading-2', heading2Styles, fontWeightsStyles, weight)}
      style={{ fontSize: fontSize, lineHeight: fontSize }}
      dangerouslySetInnerHTML={{
        __html: text
      }}
    />
  );
};

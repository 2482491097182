import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { ReactComponent as CrossIcon } from '../../svg/Empty.svg';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import removeFromCartMutation from './queries/removeFromCart.gql';
import incrementQuantityMutation from './queries/incrementQuantity.gql';
import decrementQuantityMutation from './queries/decrementQuantity.gql';
import cartQuery from './queries/cartQuery.gql';
import { theme } from '../Theme';
import { PushPrice } from '../ui/StyledPrice';

const Wrapper = styled('div')`
  display: flex;
  background: white;
  border-bottom: 1px solid ${theme.colors.border};
  padding: 1rem 0;

  ${theme.above.lg} {
    padding: 23px 0 30px 0;
  }

  > :first-child {
    min-width: 30%;
    margin-right: 20px;
  }
  > :last-child {
    max-width: 60%;
    width: 100%;
  }

  .price,
  .new-price {
    text-align: end;
    font-size: 16px;
    line-height: 24px;
  }

  .old-price,
  .percentage {
    font-size: 10px;
    line-height: 14px;
  }
  .red-tilt-wrapper {
    width: 42px;
  }

  [data-testid='item-price'] {
    align-self: flex-end;
  }
  .image-wrapper {
    background: ${theme.colors.lightgrey};
    img {
      mix-blend-mode: darken;
    }
  }
`;

const ProductName = styled('div')`
  h2 {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }
  h3 {
    font-size: 13px;
    line-height: 1.2;
    font-weight: 400;
  }

  a {
    color: ${theme.colors.black};
    text-decoration: none;
  }
  .attribute {
    display: block;
  }
`;

const ProductDetail = styled('section')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .adjust-price {
    margin-top: 20px !important;
    display: flex;
    justify-content: space-between;
  }
`;

const ItemDetails = styled('div')`
  display: block;
  position: relative;
`;

const AdjustQty = styled('div')`
  display: flex;
  align-items: flex-end;
  /* Plus/minus buttons */
  button {
    border: 1px solid ${theme.colors.border};
    background: #f2f2f2;
    color: black;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    :focus {
      outline: none;
    }
  }
  /* Count */
  span {
    border-top: 1px solid ${theme.colors.border};
    border-bottom: 1px solid ${theme.colors.border};
    padding: 0 10px;
    font-size: 13px;
    min-width: 28px;
    line-height: 1.7;
    display: inline-block;
  }
`;

const RemoveItem = styled('div')`
  position: absolute;
  left: 96%;
  top: 0;

  ${theme.above.lg} {
    left: 103%;
  }

  button {
    background: transparent;
  }
  svg {
    height: 15px;
    width: 13px;
  }
`;

const CartItem = ({ item, className = '' }) => {
  const {
    isVariant,
    variantImage,
    hasVariantImage,
    variantValues
  } = getCartItemVariant(item);

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  return (
    <Wrapper className={className}>
      <FlyoutTrigger id="cart-flyout">
        {flyout => (
          <div className="image-wrapper" onClick={flyout.hideTarget}>
            {item.product.images.length > 0 && (
              <Link to={item.product.primaryRoute.path}>
                <Image
                  aspect="1:1"
                  sizes="5rem"
                  src={
                    hasVariantImage
                      ? variantImage.url
                      : item.product.images[0].url
                  }
                  alt={
                    hasVariantImage
                      ? variantImage.alt
                      : item.product.images[0].alt
                  }
                  quality={80}
                />
              </Link>
            )}
          </div>
        )}
      </FlyoutTrigger>

      <ProductDetail>
        <ItemDetails>
          <FlyoutTrigger id="cart-flyout">
            {flyout => (
              <ProductName onClick={flyout.hideTarget}>
                <Link to={item.product.primaryRoute.path}>
                  {item?.product?.subName && (
                    <h2 data-testid="brand-name">{item.product.subName}</h2>
                  )}
                  <h3 data-testid="item-name">{item.product.name}</h3>
                </Link>
                {isVariant && (
                  <h3 style={{ marginTop: '6px' }}>
                    {item.variantOptionNames[0] + ': '}
                    {variantValues.join(', ')}
                  </h3>
                )}
              </ProductName>
            )}
          </FlyoutTrigger>
          <RemoveItem>
            <button
              onClick={() =>
                removeFromCart({ itemId: item.id, product: item.product })
              }
              data-testid="remove-from-cart"
            >
              <CrossIcon />
            </button>
          </RemoveItem>
        </ItemDetails>
        <div className="adjust-price">
          <AdjustQty>
            <button
              data-testid="decrement-quantity"
              disabled={item.quantity === 1}
              onClick={() =>
                item.quantity !== 1 && decrementQuantity({ itemId: item.id })
              }
            >
              -
            </button>
            <span data-testid="item-quantity">{item.quantity}</span>
            <button
              data-testid="increment-quantity"
              onClick={() => incrementQuantity({ itemId: item.id })}
            >
              +
            </button>
          </AdjustQty>
          <PushPrice
            data-testid="item-price"
            price={item.total}
            previousPrice={item.previousTotal}
          />
        </div>
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;

import React from 'react';
import { theme } from '../../Theme';
import { cx, css } from 'linaria';
import { fontWeightsStyles } from '../sharedStyles/FontWeightStyles';

export const heading1Styles = css`
  font-size: ${theme.fontSizes.heading1};
  line-height: ${theme.lineHeights.heading1};
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colors.black};
`;

/**
 * The Heading1 function is a component that renders an H1 element with the
 * specified font weight.
 *
 *
 * @param {String} weight Set the font weight of the heading
 * @param {String} text Text to be presented in component
 *
 * @return A h1 with a class of heading-1
 *
 * @docauthor Anders Zetterström @ R3
 */
export const Heading1 = ({ weight, text }) => {
  return (
    <h1
      className={cx('heading-1', heading1Styles, fontWeightsStyles, weight)}
      dangerouslySetInnerHTML={{
        __html: text
      }}
    />
  );
};

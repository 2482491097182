import CategoryLink from '@jetshop/ui/CategoryLink';
import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import AnimateHeight from 'react-animate-height';
import { MenuContentCategory } from './MenuContentCategory';

const borderColor = '#e8e8e8';

// Limit the menu to our global MaxWidth
const MaxWidth = styled('div')`
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 40px;

  .menucontent {
    border-top: 1px solid ${theme.colors.border};
    height: 100%;
    width: 100%;
    padding-top: 20px;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.black};
    opacity: 0.8;
    display: block;
    padding: 1em 0.5em 1.25em 0.5em;
    :hover {
      opacity: 1;
      /* text-decoration: underline; */
    }
  }
`;

// Set the submenu a minimum of 50% of the browser view, but fit the content
// so it doesn't always extend to 100%.
const ContentFit = styled('div')`
  width: fit-content;
  margin: 0 auto;
  min-width: 100vw;
`;

// We need to put the border here (inside the Wrapper), because if we put it directly
// on the Wrapper it will still be shown when the Wrapper's height is 0
const InnerWrapper = styled('div')`
  border: 1px solid ${borderColor};
  border-width: 1px 0 1px 0;
  width: 100%;
`;

const Wrapper = styled('section')`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  /* min-height: 400px; */
  background: ${theme.colors.white};
  overflow: hidden;
  z-index: 10;
  will-change: height;
  transition: height 0.3s ease-in-out;

  h2 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1em;
  }
`;

const SubCategoryWrapper = styled('div')`
  padding-bottom: 30px;
  min-width: 210px;
  column-count: ${props => props.columncount};
  max-width: fit-content;
  column-gap: 100px;
  text-align: left;
  column-rule: 1px solid ${theme.colors.border};
  margin: 0px 30px;

  a {
    font-size: 14px;
    line-height: 26px;
    padding: 2px 0 !important;
    margin-left: -30px;
  }
`;

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  const menuContentId = 174;
  const columns = Math.ceil(activeCategory?.subcategories?.length / 6);

  const SortPrioCategories = activeCategory => {
    if (!activeCategory) return null;

    let prio = activeCategory?.subcategories?.filter(cat => cat.externalId);
    prio = prio.sort((a, b) => a.externalId - b.externalId);

    return prio;
  };

  const SortRestCategories = activeCategory => {
    if (!activeCategory) return null;

    let rest = activeCategory?.subcategories?.filter(cat => !cat.externalId);
    rest = rest.sort((a, b) => a.name - b.name);

    return rest;
  };

  const prioCategories = SortPrioCategories(activeCategory);
  const restCategories = SortRestCategories(activeCategory);

  return (
    <Wrapper>
      <InnerWrapper>
        <ContentFit>
          <AnimateHeight height={pose === 'open' ? 'auto' : 0} duration={350}>
            <MaxWidth>
              <SubCategoryWrapper
                className="subcategories"
                columncount={columns > 0 ? columns : ''}
              >
                {activeCategory && (
                  <>
                    {prioCategories &&
                      prioCategories.map(cat => {
                        return (
                          <React.Fragment key={'prio-cat-' + cat.id}>
                            <CategoryLink category={cat} onClick={closeNav}>
                              {cat.name}
                            </CategoryLink>
                          </React.Fragment>
                        );
                      })}
                    {restCategories &&
                      restCategories.map(cat => {
                        return (
                          <React.Fragment key={'rest-cat-' + cat.id}>
                            <CategoryLink category={cat} onClick={closeNav}>
                              {cat.name}
                            </CategoryLink>
                          </React.Fragment>
                        );
                      })}
                  </>
                )}
              </SubCategoryWrapper>
              <div className="menucontent">
                {menuContentId && (
                  <MenuContentCategory
                    menuContentId={menuContentId}
                    closeNav={closeNav}
                  />
                )}
              </div>
            </MaxWidth>
          </AnimateHeight>
        </ContentFit>
      </InnerWrapper>
    </Wrapper>
  );
};

export default SubMenuWrapper;

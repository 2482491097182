import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';

const ContentPlacerWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 50px;

  ${theme.below.lg} {
    padding: 1rem;
  }

  &.TOP {
    align-items: flex-start;
  }
  &.MIDDLE {
    align-items: center;
  }

  &.BOTTOM {
    align-items: flex-end;
  }

  &.LEFT {
    > * {
      text-align: left;
      margin-left: 0;
      margin-right: auto;
    }
    justify-content: flex-start;
  }

  &.CENTER {
    justify-content: center;

    > * {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.RIGHT {
    justify-content: flex-end;
    > * {
      text-align: right;
      margin-left: auto;
      margin-right: 0;
    }
  }

  > * {
    &:not(.tint-layer) {
      z-index: 0;
    }
  }
`;

export const TintLayerWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
`;

// Position to relative parent
export const ContentPlacer = ({
  verticalPosition,
  horizontalPosition,
  tintLayer,
  children
}) => {
  return (
    <ContentPlacerWrapper
      className={cx(
        verticalPosition?.value,
        horizontalPosition?.value,
        'content-placer'
      )}
    >
      {tintLayer?.value && <TintLayerWrapper className={'tint-layer'} />}
      {children}
    </ContentPlacerWrapper>
  );
};

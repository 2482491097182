import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import { theme } from '../../../Theme';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { GridChannelSelector } from '../ChannelSelector/GridChannelSelector';

const NewDrawer = styled('nav')`
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: ${props => props.topPosition}px;
  height: calc(100vh - ${props => props.topPosition}px);
  z-index: 4;
  max-width: 100%;
  ${theme.below.lg} {
    max-width: 90%;
  }

  width: ${props => props.size}px;
  transform: translateX(${props => (props.isOpen ? 0 : props.size)}px);
  transition: transform 0.2s ease-in-out;
  right: 0;

  &.left {
    left: 0;
    right: auto;
    transform: translateX(${props => (props.isOpen ? 0 : -props.size)}px);
  }
`;

export const PositionedDrawer = ({ isOpen, size = 332, children, left }) => {
  return (
    <NewDrawer
      topPosition={0}
      isOpen={isOpen}
      size={size}
      className={left ? 'left' : ''}
    >
      {children}
    </NewDrawer>
  );
};

export default function ChannelFlyout({ size = 332, left = false }) {
  const { channels, selectedChannel, updateChannel } = useContext(
    ChannelContext
  );

  return (
    <>
      <DrawerTarget id="channel-drawer">
        {drawer => (
          <PositionedDrawer isOpen={drawer.isOpen} size={size} left={left}>
            <GridChannelSelector
              channels={channels}
              selectedChannel={selectedChannel}
              updateChannel={updateChannel}
            />
          </PositionedDrawer>
        )}
      </DrawerTarget>
    </>
  );
}

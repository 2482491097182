import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { SharedSwiper } from '../ui/slider/SharedSwiper';
import { Title } from './Title';
import { Above, Below } from '@jetshop/ui/Breakpoints';

const DynamicRowContainer = styled('section')`
  padding-top: ${props => props.padding}px;
  background: ${theme.colors.lightgrey};

  .heading-2 {
    margin-bottom: 1rem;
  }
`;

const DynamicBoxRowWrapper = styled('div')`
  width: 100%;
  padding-bottom: ${props => props.padding}px;
  display: grid;
  grid-template-columns: 1fr minmax(auto, ${theme.maxWidth}) 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  ${theme.below.lg} {
    padding-left: 1rem;
  }

  .shared-swiper-wrapper {
    grid-area: 1 / 2 / 2 / 4;

    .shared-swiper {
      .swiper-slide {
        margin-top: auto;
      }
    }
  }

  .content-placer {
    /* Bottom padding so text doesn't overlay button */
    padding: 20px 20px 83px 20px;

    .buttons-row {
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: auto;
      top: auto;
    }
  }
`;

export const DynamicBoxRow = ({
  rowPadding,
  header,
  children,
  sliderProps
}) => {
  const desktopSlidesPerView = children.length < 4 ? children.length : 4;
  const mediumSlidesPerView = children.length < 2.3 ? children.length : 2.3;
  const mobileSlidesPerView = children.length < 1.3 ? children.length : 1.3;
  return (
    <Above breakpoint="lg">
      {aboveLg => {
        return (
          <Below breakpoint="sm">
            {belowMd => {
              return (
                <DynamicRowContainer
                  className="dynamic-box-container"
                  padding={theme.spaceDict[rowPadding?.value] ?? 0}
                >
                  {header?.value && <Title text={header} />}
                  <DynamicBoxRowWrapper
                    className="dynamic-box"
                    padding={theme.spaceDict[rowPadding?.value] ?? 0}
                  >
                    <SharedSwiper
                      slidesPerView={
                        aboveLg
                          ? desktopSlidesPerView
                          : belowMd
                          ? mobileSlidesPerView
                          : mediumSlidesPerView
                      }
                      spaceBetween={aboveLg ? 20 : 10}
                      loop={children.length > 4}
                      {...sliderProps}
                    >
                      {children}
                    </SharedSwiper>
                  </DynamicBoxRowWrapper>
                </DynamicRowContainer>
              );
            }}
          </Below>
        );
      }}
    </Above>
  );
};

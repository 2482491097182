import React from 'react';
import { styled } from 'linaria/react';
import { Heading2 } from '../ui/text/Heading2';

const TitleWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = ({ text }) => {
  return (
    <TitleWrapper>
      <Heading2 text={text?.value} weight={'bold'} />
    </TitleWrapper>
  );
};

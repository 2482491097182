import PropTypes from 'prop-types';
import React from 'react';
import { styled } from 'linaria/react';
import { StockIcon } from '../StockIcon';
import { Heading4 } from '../../ui/text/Heading4';

const StockStatusWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const StockStatusIndicator = ({ status, text, ...props }) => {
  return (
    <StockStatusWrapper className="stockstatus-wrapper" {...props}>
      <StockIcon status={status} />
      <Heading4 text={text} weight={'regular'} />
    </StockStatusWrapper>
  );
};

StockStatusIndicator.propTypes = {
  status: PropTypes.oneOf(['inStock', 'outOfStock', 'notifyWhenBack']),
  text: PropTypes.string
};

export default StockStatusIndicator;

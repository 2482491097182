import React from 'react';
import { theme } from '../../Theme';
import { cx, css } from 'linaria';
import { fontWeightsStyles } from '../sharedStyles/FontWeightStyles';

export const heading4Styles = css`
  font-size: ${theme.fontSizes.heading4};
  line-height: ${theme.lineHeights.heading4};
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colors.black};
`;

/**
 * The Heading4 is a component that returns
 * an HTML element with the className 'heading-4'. The Heading4 function also takes
 * an optional prop called weight. If no weight is passed, it defaults to 400. 
 
 *
 * @param {String} weight Set the font weight of the heading (select classnames from fontWeightsStyles)
 * @param {String} text Text to be presented in component
 *
 * @return A h4 element with the class of heading4 and font-weight styles
 *
 * @docauthor Anders Zetterström @ R3
 */
export const Heading4 = ({ weight, text }) => {
  return (
    <h4
      className={cx('heading-4', heading4Styles, fontWeightsStyles, weight)}
      dangerouslySetInnerHTML={{
        __html: text
      }}
    />
  );
};

import React, { useRef } from 'react';
import { styled } from 'linaria/react';
import 'swiper/swiper-bundle.min.css';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SliderButton } from './SliderButton';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../../Theme';

const SharedSwiperWrapper = styled('div')`
  width: 100%;
  max-width: 100vw;
  .swiper-button-prev,
  .swiper-button-next {
    width: fit-content;
    :after {
      display: none;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }

  .swiper-pagination {
    display: flex;
    padding: 20px 1rem;
    background: ${theme.colors.lightgrey};
    bottom: 0;
  }

  .swiper-pagination-bullet {
    flex: 1;
    height: 3px;
    background: ${theme.colors.white};
    opacity: 1;
    border-radius: 0;
    margin: 0 !important;

    &.swiper-pagination-bullet-active {
      background: ${theme.colors.red};
    }
  }
`;

export const SharedSwiper = ({
  children,
  loop = true,
  full,
  shouldAutoplay,
  ...props
}) => {
  const swiperRef = useRef(null);
  const desktopSlideCheckValue = 4;
  const mobileSlideCheckValue = 2;

  const desktopSlidesPerView =
    children.length < desktopSlideCheckValue
      ? children.length
      : desktopSlideCheckValue;
  const mobileSlidesPerView =
    children.length < mobileSlideCheckValue
      ? children.length
      : mobileSlideCheckValue;

  //   const handlePrevClick = () => {
  //     swiperRef?.current?.swiper?.slidePrev();
  //   };
  const handleNextClick = () => {
    swiperRef?.current?.swiper?.slideNext();
  };

  return (
    <>
      <SharedSwiperWrapper
        className="shared-swiper-wrapper"
        length={children?.length ?? 1}
      >
        <Above breakpoint="lg">
          {matches => {
            const slideCheckValue = matches
              ? desktopSlideCheckValue
              : mobileSlideCheckValue;
            return (
              <Swiper
                ref={swiperRef}
                effect={'cover'}
                grabCursor={true}
                threshold={2}
                centeredSlides={false}
                slidesPerView={
                  full
                    ? 1
                    : matches
                    ? desktopSlidesPerView
                    : mobileSlidesPerView
                }
                className="shared-swiper"
                loop={loop}
                modules={
                  (shouldAutoplay || props?.navigation) && [
                    Autoplay,
                    Pagination
                  ]
                }
                autoplay={shouldAutoplay && { delay: 5000 }}
                speed={shouldAutoplay && 800}
                {...props}
              >
                {children?.map((child, index) => (
                  <SwiperSlide
                    key={'shared-swiper-slide-' + index}
                    className={'swiper-slide'}
                  >
                    {child}
                  </SwiperSlide>
                ))}

                {/* <div className="swiper-button-prev">
              <SliderButton direction={'left'} callback={handlePrevClick} />
            </div> */}
                {/* Only show button if we loop or there are more slides than max value */}
                {(loop || children.length > slideCheckValue) && matches && (
                  <div className="swiper-button-next">
                    <SliderButton
                      direction={'right'}
                      callback={handleNextClick}
                    />
                  </div>
                )}
              </Swiper>
            );
          }}
        </Above>
      </SharedSwiperWrapper>
    </>
  );
};
